import React, {useState, useEffect} from 'react';
import {Box, Typography, FormControlLabel, Checkbox, Button, IconButton } from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import { PlaneEditor } from './planeEditor';
import { Warning as WarningIcon, Help as HelpIcon } from '@material-ui/icons';


/**
 * main plate annotation panel
 * @param props 
 * @returns 
 */
export function EditorsPanel(props: any) {

 
  const [value, setValue] = useState(0); // integer state

  const update = () => setValue(v => v + 1);
/**
 * general callback
 * @param arg 
 * @param value 
 */
const handleCallback = (arg: string, value: any = null) => {
  try {

    switch (arg) {
        case 'PlanesValidationChanged':
        case 'PlanePlatesChanged':
        case 'PlaneItemsChanged':
        case 'PlanesSelectedObjectChanged':
        case 'PlanesAnnotationsChanged':
        case 'PlanesSettingsChanged':
            update();
            break;

        case 'problematic':
            annotations.problematic = value;
            update();
            break;

        case 'newPlate':
            common.notify('PlanesAddPlate');
            break;

        case 'newWheel':
            common.notify('PlanesAddWheel');
            break;

        case 'newPlane':
          common.notify('PlanesAddPlane');
          break;
        
        case 'save':
          common.notify('PlanesSave');
          break;

        case 'cancel':
          common.notify('PlanesCancel');
          break;

        case 'help':
          common.notify('PlanesShowHelp');
          break;
    }

  } catch (ex) {
    console.error('failed to handle callback: ', ex);
  }
}

const isDisabled = (name:string) => {
  try {
    const settings = common.plates.settings;
    switch(name) {
      case 'newPlane': return !settings.tagPlanesPlane;
      case 'newPlate': return !settings.tagPlanesPlate;
      case 'newWheel': return !settings.tagPlanesWheel;
    }

  } catch (ex) {
    console.error('failed on disabled:', ex);
  }
}

/**
 * subscribe to global notifications
 */
useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleCallback(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});

}, []);

const classes = useStyles();
const annotations = common.planes.annotations || [];
const items = annotations.items;
const validationMessage = common.planes.validationMessage;

const w = 'calc(100% - 500px)';


return <Box display="flex" flex="1" flexDirection="row">


    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
        <FormControlLabel style={{margin: "4px"}}
          value="start"
          control={<Checkbox color="primary" checked={annotations.problematic}
          onChange={ev => handleCallback('problematic', ev.target.checked)}  />}
          label={<span>problematic</span>}
        />
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
        <IconButton onClick={() => handleCallback('help')} style={{width:'20px', height:'20px', margin:'4px'}}><HelpIcon/></IconButton>
        <Button disabled={isDisabled('newPlane')} variant="outlined" size='small' style={{margin: '4px'}} onClick={() => handleCallback('newPlane')}>+ plane</Button>
         </Box>
        <Box height="4px"/>
        <Box display="flex" flexDirection="row">
            <Button className={classes.button}  onClick={() => handleCallback('save')}>ok, next</Button>
            <Button className={classes.button}   onClick={() => handleCallback('cancel')}>Cancel</Button>
        </Box>

        {validationMessage && <Box display="flex" flexDirection="row" alignItems="center" margin="4px">
          <WarningIcon width="20px" height="20px" style={{color:'orangeRed', marginRight:'4px'}}></WarningIcon>
          <Typography style={{fontWeight:'bold', fontSize: '14px'}}>{validationMessage}</Typography>
        </Box>}

          <Box display="flex" flexDirection="row">
          </Box>


    </Box>

    <Box display="flex" flexDirection="row" width={w} overflow="hidden" >
      <Box display="flex" flexDirection="row" overflow="auto">
        {items.map((i:any) => <PlaneEditor item={i}></PlaneEditor>)}
      </Box>
    </Box>

  
</Box>
}

const useStyles = makeStyles({

  radio: {
    margin: 0,
    padding: 0,
    fontSize: 40,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
    fontSize:40,
  },
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

});