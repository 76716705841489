import React, {useState, useEffect } from 'react';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, IconButton, CircularProgress, Typography, Checkbox, Tooltip, Select, MenuItem, Button } from "@material-ui/core";
import { Search, FilterList, FileCopy, Refresh, SwapCalls } from '@material-ui/icons';
import common from '../../services/commonService';
import { TriSwitch } from '../axles/triSwitch';

/**
 * filter control for plates
 * @param props 
 * @returns 
 */
export function PlanesFilter(props: any) {
  
  const [value, setValue] = useState(0); // integer state
  const [showFilter, setShowFilter] = useState(false);

  /**
   * callbacks
   * @param arg 
   * @param value 
   */
  const handleCallback = (arg: string, value: any) => {
    try {
      switch (arg) {
        case "ToggleFilter":
          setShowFilter(!showFilter);
          break;

        case "PlanesFilterEnable":
          if (!filter.canFilter)
            setShowFilter(false);
          setValue(v => v + 1);
          break;

          case 'PlanesLoadStateChanged':
            setValue(v => v + 1);
            break;

        case "planes":
        case 'wheels':
        case "plates":
        case "problematic":
     
          filter[arg] = value;
          setValue(v => v + 1);
          common.notify('PlanesFilterChanged');
          break;

        case "RefreshList":
          common.notify('PlanesFilterChanged');
          break;


        case "Copy":
          common.notify('PlatesCopyListToClipboard');
          break;

        case "Scan":
          common.notify("PlanesForceScan");
          break;
      }

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

  /**
   * register for callbacks
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallback(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);


  const comboOptions:string[] = ["Tagged", "Not tagged", "Visited", "Not visited", 
  "Validation OK", "Validation error"];

const filter = common.planes.filter;

const loading = common.planes.loadingDataset;

  // rendering
  return (
    <Box display="flex" flexDirection="column"  >
    <Box display="flex" flexDirection="column"  >

    {loading && <Box display="flex" flexDirection="row" style={{padding:'10px', background:'orange'}}>
          <CircularProgress style={{width:'20px', height: '20px', marginRight: '4px'}}/>
          <Typography style={{fontWeight:'bold', fontSize: '14px'}}>Loading dataset</Typography>
          </Box>}


      <Box display="flex" flexDirection="row" alignItems="center">
      <Tooltip title= 'Planes filter' placement="left">
         <IconButton  style={{height:20}}
        onClick = {(e) =>  handleCallback("ToggleFilter", e) }
          color="primary" >
        <FilterList/>
      </IconButton>
      </Tooltip>
      <b>Filter and search</b>

      <Box display="flex" flex="1"></Box>

      { showFilter && filter.canFilter  && 
      <Box display="flex" flexDirection="row">

      <Tooltip title="Refresh list">
        <IconButton style={{height:20}}
        onClick={(e) => handleCallback("RefreshList", e)}>
          <Refresh></Refresh>
        </IconButton>
        </Tooltip>
          </Box>
      }

      </Box>

      { showFilter && filter.canFilter && 
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" marginLeft="8px" height="150px" maxHeight="150px" overflow="auto">
     
        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'red', margin:'2px'}}/>
        <Box display="inline" width="80px" margin="6px">problem:</Box>
        <TriSwitch value={filter.problematic} onChange={(e:any) => handleCallback('problematic', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'skyBlue', margin:'2px'}}/>
        <Box display="inline" width="80px" margin="6px">planes:</Box>
        <TriSwitch value={filter.planes} onChange={(e:any) => handleCallback('planes', e) }/>
        </Box>

       
        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'lawnGreen', margin:'2px'}}/>
        <Box display="inline" width="80px" margin="6px">plates:</Box>
        <TriSwitch value={filter.plates} onChange={(e:any) => handleCallback('plates', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'orange', margin:'2px'}}/>
         <Box display="inline" width="80px" margin="6px">wheels:</Box>
        <TriSwitch value={filter.wheels} onChange={(e:any) => handleCallback('wheels', e) }/>
        </Box>

        </Box>
       
  </Box>


  <Box display="flex" flexDirection="row" alignItems="center" style={{marginLeft:10, height:30}}>
  
  <Box display="flex" flex="1"/>
        <Tooltip title='Copy list to clipboard'>
    <IconButton  onClick={() => handleCallback('Copy', null) } >
          <FileCopy style={{color: '#29367d'}} />
        </IconButton>
        </Tooltip>
      </Box>

    




  </Box>
}
    


 

      </Box>
      </Box>
  );

}