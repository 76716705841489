import {  makeStyles, Paper, Theme, Typography, Box, Button } from "@material-ui/core";
import { Tooltip,   IconButton, Toolbar, LinearProgress, 
  TextField,
  Badge, Select, MenuItem  } from "@material-ui/core";
import { useState } from "react";
import * as React from "react";
import { Warning as WarningIcon, BugReport, FolderOpen, Search as SearchIcon,
  Settings, Stop, PlayArrow, FolderSpecial, Home as HomeIcon } from '@material-ui/icons';
import { LoginDialog } from "../components";
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import common from "../services/commonService";
import { LoggerDialog } from './LoggerDialog';
import { TestsDialog } from './TestsDialog';
import { SettingsDialog } from './settingsDialog';
import { ChangelogDialog } from './changelogDialog';
import  AlertDialog  from './alertDialog';
import { AlertData } from "../data/appData";
import { GatewayControl } from './gateway';
import { DatasetsTree } from './general/datasetsTree';
import EntryJsonDialog from './general/entryJsonDialog';
import platesService from "../services/platesService";
import { ConfirmData } from '../services/commonService';
import  ConfirmationDialog  from './confirmDialog';

interface Props {
	size: number;
	color: "red" | "blue" | string;
}

export function SearchControl() {
  const [value, setValue] = React.useState(0);

  const search = common.app.search;
  const handleChanges = (key: string, value: any) => {
    try {
      switch(key) {
        case 'ToggleSearch':
          search.open = !search.open;
          setValue(v => v + 1);
          common.notify('SearchOpenChanged');
          break;

        case 'SearchKeyDown':
          if (value === 13) 
            common.notify('SearchFind');
          break;

        case 'TextChanged':
          search.searchText = value;
          setValue(v => v + 1);
          break;

        


      }
    } catch (ex) {
      console.error('failed to handle search control changes:', ex);
    }
  }


  return (
      <Box display="flex" flexDirection="row" alignItems="center">
      <Tooltip title={<big>Search for an image by ID</big>}>
        <IconButton onClick={() => handleChanges('ToggleSearch', null) } >
          <SearchIcon style={{color: '#29367d'}}/>
        </IconButton>
      </Tooltip>
      {search.open && 
       <TextField
       InputProps={{style: {fontSize:'10px', width:'150px'}}}
       onChange={(e) => handleChanges('TextChanged', e.target.value)}
       value={search.searchText} onKeyDown={(e) => handleChanges('SearchKeyDown', e.keyCode)}></TextField>
      }
    </Box>


        
  )

}

export function Ribbon(props: Props) {
	const { size, ...other } = props;
  const classes = useStyles(props);
  
  const [treeOpen, setTreeOpen] = React.useState(false);
  const [entryJsonOpen, setEntryJsonOpen]  = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [ribbonTitle, setRibbonTitle] = React.useState('');
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  const [loggerOpen, setLoggerOpen] = React.useState(false);

  const [testsOpen, setTestsOpen] = React.useState(false);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [changelogOpen, setChangelogOpen] = React.useState(false);
  const [alertOpen, setAlertOpen ] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);


  const handleLoggerClose = (value:any) => {
    setLoggerOpen(false);
  };

  const handleTestsClose = (value:any) => {
    setTestsOpen(false);
  }


  const [percent, setPercent] = React.useState(0);
  //const [platesProgress, setPlatesProgress] = React.useState({percent: 0, working: false, total: 0, processed: 0});

  const history = useHistory();
 
const handleClose = () => {
  setOpen(false);
};

const handleSettingsClose = () => {
  setSettingsOpen(false);
  common.notify('SettingsDialogClosed');
}

const handleChangelogClose = () => {
  setChangelogOpen(false);
}


const handleDebug = () => {
  setTestsOpen(true);
  common.notify('DebugSelectDataset');
}



const handleSettings = () => {
  setSettingsOpen(true);
  common.notify('SettingsDialogOpened');
}

const location = useLocation();

const launchConfirmationDialog = (data:ConfirmData) => {
  try {
    common.confirmData = data;
    setConfirmationOpen(true);
  } catch (ex) {
    console.error('failed to launch confirmation dialog:', ex);
  }
}

const handleConfirmationClose = (value:any) => {
  try {
    setConfirmationOpen(false);
    common.notify(common.confirmData.exitNotification, value);
  } catch(ex) {
    console.error('failed to handle confirmation close');
  }
}

const updateRibbonTitle = () => {
  try  {
    switch(window.location.pathname) {
      case "/plates":
        const basename  = common.selectedPath?.split(/[\\/]/).pop();
        setRibbonTitle(`Plates: ${common.selectedDirectory} - ${basename}`);
        break;
    }

  } catch (ex) {
    console.error('failed to update ribbon title');
  }
}

const portalUrl = process.env.REACT_APP_PORTAL || '';

const handleChanges = (key: string, value: any) => {
  try {
    const sequence = common.axles.sequence;
    switch(key) {

      case 'PlanesLoadStateChanged':
        setValue(v => v + 1);
        break;

      case 'SelectDataset':
        setTreeOpen(true);
        common.notify('DatasetsTreeOpened');
        break;

      case 'TreeClosed':
        setTreeOpen(false);
        break;

      case "SelectAxlesDataset":
        common.notify('AxlesSelectDataset');
        break;

      case "GatewaySelectDataset":
      case "SelectPlatesDataset":
        common.notify('PlatesSelectDataset');
        break;

        case "ShowLogger":
          setLoggerOpen(true);
          break;

        case 'ApplicationModeChanged':
        case "LoggedIn":
          setValue(v => v + 1);
          break;

        case "ReloadDataset":
          common.notify('LoadLastDataset');
          break;


        case "StartGrinding":
          common.notify('StartGrinding');
          break;

        case "StopGrinding":
          common.notify("StopGrinding");
          break;

        case "AxlesLoadStateChanged":
        case "ScriptName":
          common.app.grinder.script = value;
          setValue(v => v + 1);
          break;

          case "UpdateRibbonTitle":
          updateRibbonTitle();
          setValue(value => value + 1);
          break;

        case 'SearchOpenChanged':
        case 'ItemStatesProgress':
        case 'DatasetsSelectionChanged':
        case 'GeneratingPlates':
        case 'LoadingDataset':
        case 'LoadingPlate':
        case 'AxlesLoadingDataset':
        case 'AxlesLoadingRecord':
        case 'AxlesLoadingImage':
        case 'AxleStateUpdated':
          setValue(value => value + 1);
          break;

        case 'ErrorCountChanged':
          setValue(value => value + 1);
          break;

        case 'PlateStateUpdated':
        case 'PlatesSettingsLoaded':
        case 'PlateSettingsChanged':  
        case 'ScriptStateChanged':
          setValue(value => value + 1);
          break;

        case 'DebugStatusUpdate':
          setValue(value => value + 1);
          break;

        case 'TestIconClicked':
          window.location.href = portalUrl;
          break;

        case 'LaunchAlert':
          setAlertOpen(true);
          break;
          
        case 'AlertClosed':
          setAlertOpen(false);
          common.notify('AlertDismissed');
          break;

        case 'ShowJsonRequest':
          setEntryJsonOpen(true);
          break;

        case 'EntryJsonClosed':
          setEntryJsonOpen(false);
          break;

        case 'LaunchLoginDialog':
          setOpen(true);

        case 'PlatesCharPageChanged':
          setValue(v => v + 1);
          break;

        case "LaunchConfirmationDialog":
          launchConfirmationDialog(value);
          break;
    
    }

  } catch (ex) {
    console.error('failed on ribbon handle changes: ', key, ex);
  }
}

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });

    // if (common.launchLogin) {
    //   common.launchLogin = false;
    //   setOpen(true);
    // }

    return (() => {
      subscription.unsubscribe();
    });

  
  }, []);

  const basename  = common?.datasets?.selectedPath?.split(/[\\/]/).pop() || '-';

  const axlesAlias = common.axles.dataset?.dataset?.alias;

  const TEST_ENVIRONMENT = !process.env.REACT_APP_SERVER_URL?.includes('pandora.tattile.com');
  
  const isDisabled = (name:string):boolean => {
    try {
      switch(name) {
        case 'Json': return common.app.context.json === '';
      }
      return false;
    } catch (ex) {
      return true;
    }
  }


	return (
    <div>
      <LoginDialog open={open} onClose={handleClose}></LoginDialog>
	 { common.loggedIn &&		 	<Paper className={classes.box} {...other}>
  <Toolbar className={classes.toolbar}>

  { common.automated && <GatewayControl/> }

{ TEST_ENVIRONMENT && !common.automated &&
    <Tooltip title="Test environment is active">
    <HomeIcon onClick={() => handleChanges('TestIconClicked', null)} style={{cursor:'pointer', color:'crimson', padding:'0px', margin:'0px', marginRight:'10px', height:'40px', width:'40px'}} />
    </Tooltip>
}

      { window.location.pathname === '/datasets' && 
        <Box flexDirection="row">
           <Typography variant="h6">
              Datasets - {basename}
           </Typography>
        </Box>
      }

      { window.location.pathname === '/axles' && 
        <Box flexDirection="row">
          <Typography variant="h6">
            Axle counter {common.axles.taggedRecords}/{common.axles.totalRecords}
          </Typography>
        </Box>
      }

    

      {common.axles.loadingRecord &&
        <Box display="flex" flexDirection="row" alignItems="center" >
          <Typography variant="h6">
            &nbsp; Loading seqeunce &nbsp;
          </Typography>
          <LinearProgress  style={{width:50, height:12, margin: 2}} />
        </Box>
      }

      {common.axles.loadingImage &&
        <Box display="flex" flexDirection="row" alignItems="center" >
          <Typography variant="h6">
            &nbsp; Loading image &nbsp;
          </Typography>
          <LinearProgress  style={{width:50, height:12, margin: 2}} />
        </Box>
      }

      {common.axles.loadingDataset &&
        <Box display="flex" flexDirection="row" alignItems="center" >
          <Typography variant="h6">
            &nbsp; Loading dataset &nbsp;
          </Typography>
          <LinearProgress  style={{width:50, height:12, margin: 2}} />
        </Box>
      }



      {/* { window.location.pathname === '/inside' && 
        <Box flexDirection="row">
          <Typography variant="h6">
            Inside inspection
          </Typography>
        </Box>
      } */}

      {/* { window.location.pathname === '/inside2' && 
        <Box flexDirection="row">
          <Typography variant="h6">
            Inside inspection
          </Typography>
        </Box>
      } */}

      { window.location.pathname === '/plates' && common.mode === 'AXLES' && 
        <Box flexDirection="row">
          <Typography variant="h6">Axles: {axlesAlias} &nbsp;
           {common.axles.totalRecords}</Typography>
        </Box>
      }   

{ common.mode === 'PLANES' && 
        <Box flexDirection="row">
          <Typography variant="h6">Planes: {common.plates.settings.datasetName} &nbsp;
           {common.planes.unfilteredRecords?.length}</Typography>
          
        </Box>
      }   

      { window.location.pathname === '/plates' && common.mode === 'PLATES' && 
        
              <Box flexDirection="row">
                <Typography variant="h6">
                  {  common.plates.records?.length > 0 &&
                  <Box flexDirection="row">
                      Plates tagging: {common.plates.datasetAlias} &nbsp;
                      {common.plates.totalRecords}&nbsp;
                  </Box>
                }

    
                  
                


                {  !common.plates.records?.length  && !common.axles.records?.length && !common.planes.records.length && 
                  <Box flexDirection="row">
                      Plates tagging - please select a dataset
                  </Box>
                }


                </Typography>
              </Box>
      }

    {common.plates.scanPolicy.scanning && common.plates.showStatusProgress && 
        <Box display="flex" flexDirection="row" alignItems="center">
          <LinearProgress variant="determinate"  value={common.plates.itemStatesPercentage}  style={{width:50, height:12, margin: 2}} />   
          &nbsp;{common.plates.itemStatesPercentage}%
          &nbsp;({common.plates.currentScanningPosition})
        </Box>
      }

      { common.plates.scanPolicy.scanning && 
        <Box>&nbsp;Scanning</Box>
      }

      {common.axles.scanPolicy.scanning && common.axles.showStatusProgress && 
        <Box display="flex" flexDirection="row" alignItems="center">
          <LinearProgress variant="determinate"  value={common.axles.itemStatesPercentage}  style={{width:50, height:12, margin: 2}} />   
          &nbsp;{common.axles.itemStatesPercentage}%
          &nbsp;({common.axles.currentScanningPosition})
        </Box>
      }

      { common.axles.scanPolicy.scanning && 
        <Box>&nbsp;Scanning</Box>
      }






          <Box display={common.plates.loadingDataset ? 'flex' : 'none'}>
            <b>Dataset:</b>
            <LinearProgress variant="determinate"  value={percent} style={{width:50, height:12, margin: 2 }} />
          </Box>

          <Box display={common.plates.loadingRecord ? 'flex' : 'none'} >
            <b>Record:</b>
            <LinearProgress  style={{width:50, height:12, margin: 2}} />
          </Box>

    
        <div style={{marginLeft: 'auto'}}>

        { window.location.pathname === '/axles' &&
          <IconButton edge="end" onClick={() => handleChanges('SelectAxlesDataset', null) } >
          <FolderOpen style={{color: '#29367d'}} />
        </IconButton>
          }

          { common.app.showRibbonDebugs  && 
           <Box display="inline" flexDirection="row">
           
          <Select className={classes.combo}   label="Plate type:"
          value={common.app.grinder.script || ''} 
          onChange={e =>handleChanges("ScriptName", e.target.value)}>
              {common.app.grinder.scripts?.map((pt:any) => ( <MenuItem value={pt}>{pt}</MenuItem>))}
        </Select>   

            <Tooltip title="Stop script">
                <IconButton edge="end" onClick={() => handleChanges("StopGrinding", null) } >
                  <Stop style={{color: '#29367d'}} />
                </IconButton> 
            </Tooltip>

            <Tooltip title="Start script">
                <IconButton edge="end" onClick={() => handleChanges("StartGrinding", null)  } >
                  <PlayArrow style={{color: '#29367d'}} />
                </IconButton>
            </Tooltip>
            &nbsp;
            {common.app.grindCounter}
            &nbsp;
            {common.plates.paintCounter}
            &nbsp;
            ({common.app.grinder.startTime})
            &nbsp;
           </Box>
          } 

  


      {/* { window.location.pathname === '/plates' &&
        !common.app.search.open &&
        <Tooltip title={<big>Select dataset</big>}>
          <IconButton edge="end" onClick={() => handleChanges('SelectPlatesDataset', null) } >
          <FolderOpen style={{color: '#29367d'}} />
        </IconButton>
        </Tooltip>
          }  */}



{ window.location.pathname === '/plates' &&
        <Tooltip title={<big>Select dataset</big>}>
          <IconButton edge="end" onClick={() => handleChanges('SelectDataset', null) } >
          <FolderOpen style={{color: 'black'}} />
        </IconButton>
        </Tooltip>
          }

      { window.location.pathname === '/plates' && 
      TEST_ENVIRONMENT && !common.app.search.open &&
      common.plates?.settings?.datasetId &&
      <Tooltip title={<big>{common.plates.settings.datasetId}</big>}>
          <IconButton onClick={() => handleChanges('ReloadDataset', null) } >
            <FolderSpecial style={{color: '#29367d'}}/></IconButton>
            </Tooltip>
      }

        <Box display={common.scripts.running ? 'inline' : 'none'}>
          Script running {common.scripts.progress}
        </Box>

        <Badge color="error" 
         anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        overlap="circle"
        badgeContent={common.app.logs.errors} >
          <Tooltip title={<big>Logger</big>}>
        <IconButton edge="end" onClick={() => handleChanges('ShowLogger', true) } >
        <WarningIcon style={{color: '#29367d'}} />
        </IconButton>
          </Tooltip>
        </Badge>

        <Tooltip title="Settings">
          <IconButton edge="end" onClick={handleSettings } >
            <Settings style={{color: '#29367d'}} />
          </IconButton>
        </Tooltip>
    
        </div>
        <SearchControl/>


     
        <div style={{width: '20px'}}></div>
        <img src='./logoTattile2.png' height="40px"></img>

        <div onDoubleClick={()=> handleChanges("ToggleDebug", null)} style={{width:'20px', height:'20px'}}></div>
		  </Toolbar>
    


        <DatasetsTree open={treeOpen} onClose={() => handleChanges('TreeClosed', null)}/>
      <EntryJsonDialog open={entryJsonOpen} onClose={() => handleChanges('EntryJsonClosed', null)}></EntryJsonDialog>
      <LoggerDialog open={loggerOpen}  onClose={handleLoggerClose}></LoggerDialog>
      <TestsDialog open={testsOpen} onClose={handleTestsClose}></TestsDialog>
      <SettingsDialog open={settingsOpen} onClose={handleSettingsClose}></SettingsDialog>
      <ChangelogDialog open={changelogOpen} onClose={handleChangelogClose}></ChangelogDialog>
      <AlertDialog open={alertOpen} onClose={() => handleChanges('AlertClosed', null) } ></AlertDialog>
      <ConfirmationDialog open={confirmationOpen} onClose={handleConfirmationClose} ></ConfirmationDialog>
		</Paper> }
    </div>
	);
}

const styledBy = (property: string, props: any, mapping: any): string =>
  mapping[props[property]];
  
const useStyles = makeStyles((theme: Theme) => ({
	box: (props: Props) => ({
		display: "flex",
		alignItems: "center",
		borderRadius: 0,
		background: styledBy("color", props, {
			red: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      blue: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
      dark: "white",
		}),
		height: props.size,
		width: "100%",
  }),

	text: {
		color: "white",
  },
  
  toolbar: {
    width: '100%', 
    display:"flex", 
    boxShadow:"none"
  },
  icon: {
    color: "blue"
  },
  combo: {
    width: 200,
  },
  button: {
    background:'black',
    color:'white',
    height: '24px'
  }
}


));
