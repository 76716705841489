import React, {useState, useEffect} from 'react';
import {Box, Typography, FormControlLabel, Checkbox, RadioGroup, Radio, Button, TextField, IconButton, Select, MenuItem, Tooltip, Divider } from '@material-ui/core'

import common from '../../services/commonService';
import {  Delete as DeleteIcon } from '@material-ui/icons';

/**
 * main plate annotation panel
 * @param props 
 * @returns 
 */
export function PlaneEditor(props: any) {
    const {item} = props;
 
  const [value, setValue] = useState(0); // integer state

  const update = () => setValue(v => v + 1);
/**
 * general callback
 * @param arg 
 * @param value 
 */
const handleCallback = (arg: string, value: any = null) => {
  try {

    switch (arg) {
        case 'PlanesAnnotationsChanged':
        case 'PlanesCharHeightChanged':
            update();
            break;
  

        case 'direction':
            item.direction = value;
            update();
            common.notify('PlanesValidate');
            break;

            
        case 'plate':
            item.text = value;
            update();
            common.notify('PlanesValidate');
            break;

        case 'extra':
          item.extra = value;
          update();
          break;

          case 'whiteChars':
            item.whiteChars = value;
            update();
            break;

        case 'focus':
            item.focus = parseInt(value);
            update();
            common.notify('PlanesFocusChanged');
            break;

        case 'delete':
            common.notify('PlanesDeleteObject', item );
            break;

        case 'deleteWheel':
          common.notify('PlanesDeleteWheel', item);
          break;

          case 'deletePlate':
            common.notify('PlanesDeletePlate', item);
            break;

        case 'select':
          common.notify('PlanesSelectObject', item );
          break;

      
    }

  } catch (ex) {
    console.error('failed to handle callback: ', ex);
  }
}

const isDisabled = () => {
  try {
    const settings = common.plates.settings;
    switch(item.type) {
      case 'wheel': return !settings.tagPlanesWheel;
      case 'plane': return !settings.tagPlanesPlane;
    }
    return true;
  } catch (ex) {
    console.error('failed on isDisabled:', ex);
  }
}

/**
 * subscribe to global notifications
 */
useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleCallback(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});

}, []);

const ants = common.planes.annotations;
const selected = item === ants.selectedObject;
const directions = ants.directions;
const extras = ants.extras;
const charHeight = item.charHeight || 0;

const showPlate = item.poly?.length === 4;
const showWheel = item.wheel?.length === 4;

return <Box display="flex" width="320px" margin="4px" height="210px"
onMouseDown={() => handleCallback('select')}
flexDirection="column" style={{
  pointerEvents: isDisabled() ? 'none' : 'auto',
  border: selected ? "solid 2px blue" : "solid 2px gray"}}>


    <Box display="flex" flexDirection="row">

      
      <Box display="flex" flexDirection="column">

      <Box display="flex" flexDirection="row" alignItems="center">
      <Tooltip title="Delete plane">
      <IconButton style={{width: '20px'}} onClick={() => handleCallback('delete')}><DeleteIcon/></IconButton>
      </Tooltip>
      <Typography style={{fontWeight: 'bold'}}>Plane</Typography>
      </Box>
       
      <Box display="flex" flexDirection="row" marginLeft="4px">
        <RadioGroup  value={item.direction} onChange={e =>handleCallback("direction", e.target.value)}>
          {directions.map(d => 
          <FormControlLabel  value={d} control={<Radio size="small" />} label={d} style={{height:'24px'}} />
          )}
        </RadioGroup>
    </Box>

 

      </Box>

      <Divider orientation='vertical'/>

      <Box display="flex" flexDirection="column">

        {showPlate && <Box display="flex" flexDirection="column">

          <Box display="flex" flexDirection="row" alignItems="center">
            <Tooltip title="Delete plate">
              <IconButton style={{width: '20px'}} onClick={() => handleCallback('deletePlate')}><DeleteIcon/></IconButton>
            </Tooltip>
              <Typography style={{fontWeight: 'bold'}}>Plate</Typography>
            </Box>

  <Box display="flex" flexDirection="row" alignItems="center" >
  <Typography style={{margin:'4px', width:'50px'}}>Text:</Typography>
       <TextField style={{width: '120px'}}
       value={item.text} onChange={e => handleCallback('plate', e.target.value)}></TextField>
  </Box>
  <Box display="flex" flexDirection="row" alignItems="center">
  <Typography style={{margin:'4px', width:'50px'}}>Extra:</Typography>
  <Select value={item.extra} style={{width:'120px'}}
  onChange={e => handleCallback('extra', e.target.value)}>
    {extras.map(d => <MenuItem value={d} key={d}>{d}</MenuItem>)}
  </Select>
  </Box>

  <Box display="flex" flexDirection="row" alignItems="center">
  <FormControlLabel style={{margin: "4px", marginLeft: "0px", height: '20px'}}
         value="start"
         control={<Checkbox color="primary" checked={item.whiteChars}
         onChange={ev => handleCallback('whiteChars', ev.target.checked)}  />}
         label={<span>white chars</span>}
         />
  </Box>

  <Typography style={{marginLeft: '4px'}}>Char height: {charHeight}</Typography>
        </Box>}

        <Divider orientation="horizontal"/>

        {showWheel && <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" alignItems="center">
          <Tooltip title="Delete wheel">
      <IconButton style={{width: '20px'}} onClick={() => handleCallback('deleteWheel')}><DeleteIcon/></IconButton>
      </Tooltip>
          <Typography style={{fontWeight: 'bold'}}>Wheel</Typography>
            </Box>
         
          </Box>}

      </Box>
    </Box>

</Box>
}