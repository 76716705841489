import React, {useState, useEffect} from 'react';
import {Box } from '@material-ui/core'

import 'react-reflex/styles.css';
import common from '../../services/commonService';
import { RecordList } from './recordList';
import { useHotkeys } from 'react-hotkeys-hook';
import { PlanesDivider } from './planesDivider';
import  HelpDialog from './helpDialog';

/**
 * main plate annotation panel
 * @param props 
 * @returns 
 */
export function Planes(props: any) {


  const [value, setValue] = useState(0); // integer state
  const [helpOpen, setHelpOpen] = useState(false);

/**
 * general callback
 * @param arg 
 * @param value 
 */
const handleCallback = (arg: string, value: any) => {
  try {
    switch (arg) {
      case 'PlanesShowHelp':
        setHelpOpen(true);
        break;
    }

  } catch (ex) {
    console.error('failed to handle callback: ', ex);
  }
}

/**
 * subscribe to global notifications
 */
useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleCallback(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});

}, []);


return <Box display="flex" flex="1" flexDirection="row"  >
  <Box display="flex" width={500}>
  <RecordList></RecordList>
  </Box>
  <Box display="flex" flex="1" flexDirection="column">
    <PlanesDivider/>
  </Box>
  <HelpDialog open={helpOpen} onClose = {() => setHelpOpen(false)}></HelpDialog>
</Box>
}