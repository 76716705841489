import planesService from '../../services/planesService';
import common, { CommonService } from '../../services/commonService';

interface Point {
  x: number;
  y: number;
}
class Pan {

  ptDrag: any = null;
  ptMouse: any = null;

  ptRectOrigin: any = null;


  ctrlKey: boolean = false;
  dragOffset: any = null;

  constructor() {
    this.ptDrag = null;
  }

  clear = () => {
    try {
    } catch (ex) {
      console.error('failed to clear:', ex);
    }
  };

  click = (evt: any) => {
    try {
    } catch(ex) {
      console.error('failed on click: ', ex);
    }
  };

  mouseDown = (evt: any) => {
    try {

      this.ctrlKey = false;
      this.dragOffset = null;

      const cnvs = planesService.canvas;
      const pt = cnvs.getPoint(evt);
      if (cnvs.measuring) {
        return cnvs.measureCharHeight("MouseDown", pt);
      }


      // there is a red dot, and not in the process of creating new rect
      if (cnvs.hover && !this.ptRectOrigin) {
        const hover = this.getHoverData();
        cnvs.resizeIndex =null;
        if (hover) {
          cnvs.resizeIndex = hover.index;
          common.planes.annotations.selectedObject.focus = hover.focus;
        }
        return;

        // var pts = cnvs.getSelectedTagPoints();
        // if (pts) {
        //   cnvs.resizeIndex = pts.findIndex(p => this.pointsEqual(p, cnvs.hover));
        //   if (cnvs.resizeIndex < 0)
        //     cnvs.resizeIndex = null;
        //   return;
        // }
      }

      this.ptDrag = this.getBoundedPoint(planesService.canvas.getPoint(evt));
      const clickedTagged = cnvs.getClickedTag(evt);
      if (clickedTagged) {
        this.dragOffset = [this.ptDrag.x - clickedTagged.rect[0],this.ptDrag.y - clickedTagged.rect[1]];
        // common.notify("AxlesTagSelected", clickedTagged);
      }

      const hp = planesService.canvas.hotPoly;
      if (hp.length > 0) {
        hp.push(this.ptDrag);
        if (hp.length === 4) {
          const item = common.planes.annotations.selectedObject;
          item.poly = hp;
          planesService.canvas.hotPoly = [];
          common.notify('PlanesAnnotationsChanged');
        }
      }
      cnvs.paint("pan");
      this.ctrlKey = evt.ctrlKey;
    } catch(ex) {
      console.error('failed on mouseDown: ', ex);
    }
  };

  dragAnnotation = (evt: any) => {
    try {
      const cnvs = planesService.canvas;
      const pt = cnvs.getPoint(evt);


      const tag = common.planes.annotations.selectedObject;
      tag.rect[0] = pt.x - this.dragOffset[0];
      tag.rect[1] = pt.y - this.dragOffset[1];

      tag.rect = this.getBoundedRect(tag.rect);
      cnvs.paint("pan");
      common.axles.imageDirty = true;
    } catch (ex) {
      console.log('failed to drag annotation', ex);
    }
  }

  /**
   * get data 
   * @returns 
   */
  getHoverData() {
    try {
      const cnvs = planesService.canvas;
      if (!cnvs.hover) return null;
      const tag = common.planes.annotations.selectedObject;
      if (!tag) return null;
      const points = [cnvs.getPointsEx(tag.rect), tag.poly, cnvs.getPointsEx(tag.wheel)];
      for (let i = 0; i < 3; i++) {
        const pts = points[i];
        for (let j = 0; j < pts.length; j++) {
          if (this.pointsEqual(pts[j], cnvs.hover)){
            return {focus:i, index: j};
          }
        }
      }
      return null;
    } catch (ex) {
      console.error('failed to get hover data:', ex);
    }
  }

  mouseMove = (evt: any) => {
    try {
      const cnvs = planesService.canvas;
      if (cnvs.resizeIndex !== null) 
        return this.mouseResize(evt);
      
  

      if (this.ctrlKey)
        return this.dragAnnotation(evt);

      if (this.ptRectOrigin)
        return this.mouseAnnotate(evt);

      if (planesService.canvas.hotPoly.length > 0) {
        return this.mouseAnnotatePoly(evt);
      }

      const pt = cnvs.getPoint(evt);

      if (cnvs.measuring)
        return cnvs.measureCharHeight("MouseMove", pt);


      const ptBounded = this.getBoundedPoint(pt);
      const outOfBounds = pt.x !== ptBounded.x || pt.y !== ptBounded.y;
      cnvs.mousePos = pt;
      if (this.ptDrag && !outOfBounds) {
        cnvs.ctx.translate((pt.x - this.ptDrag.x), (pt.y - this.ptDrag.y));
        cnvs.saveMatrix();
        return;
    }
    cnvs.hover = null;

    const pts = cnvs.getSelectedTagPoints();
    if (!pts)
      return;

    const r = planesService.canvas.getAnchorRadius();
    pts.forEach((ptx:any) => {
      if (cnvs.getDistance(ptx, pt) < r) {
        cnvs.hover = ptx;
        return;
      }
    });

    } catch (ex) {
      console.error('failed on mouseMove:', ex);
    }
  };

  pointsEqual = (pt0: any | null, pt1: any | null) => {
    if (!pt0 || !pt1)
      return false;

    return pt0.x === pt1.x && pt0.y === pt1.y;
  }

  getBoundedPoint = (pt: any): any => {
    try {
      const size = planesService.canvas.imageSize;
      const x = Math.min(Math.max(pt.x, 0), size[0] - 1);
      const y = Math.min( Math.max(pt.y, 0), size[1] - 1);
      return {x, y};
    } catch (ex) {
      console.error('failed to get bounded point:', ex);
      return pt;
    }
  }

  mouseResize = (evt:any) => {
    try {
      const cnvs = planesService.canvas;
      const pts = cnvs.getFocusedPoints();
    
      const ants = common.planes.annotations;
      const tag = ants.selectedObject;
      if (!pts) return;
      const pt = this.getBoundedPoint(cnvs.getPoint(evt));

      // if resizing poly
      if (tag.focus === 1) {
        tag.poly[cnvs.resizeIndex || 0] = pt;
        cnvs.hover = pt;
        cnvs.paint("pan");
        return;
      }

      let l = 0;
      let t = 0;
      let w = 0;
      let h = 0;

      switch (cnvs.resizeIndex) {
        case 0: {
          l = pt.x;
          t = pt.y;
          w = pts[2].x - pt.x;
          h = pts[2].y - pt.y;
          }
          break;

        case 1: {
          l = pts[0].x;
          t = pt.y;
          w = pt.x - pts[0].x;
          h = pts[2].y - pt.y;
       }
        break;
        case 2: {
          l = pts[0].x;
          t = pts[0].y;
          w = pt.x - l;
          h = pt.y - t;
       }
        break;

        case 3: {
          l = pt.x;
          t = pts[1].y;
          w = pts[1].x - l;
          h = pt.y - t;
        }
        break;

        case 4: {
          l = pts[0].x;
          t = pt.y;
          w = pts[1].x - pts[0].x;
          h = pts[2].y - pt.y;
        }
        break;

        case 5: {
          l = pts[0].x;
          t = pts[0].y;
          w = pt.x - pts[0].x;
          h = pts[2].y - pts[0].y;
        }
        break;

        case 6: {
          l = pts[0].x;
          t = pts[0].y;
          w = pts[1].x - pts[0].x;
          h = pt.y - pts[0].y;
        }
        break;

        case 7: {
          l = pt.x;
          t = pts[0].y;
          w = pts[1].x - pt.x;
          h = pts[2].y - pts[0].y;
         }
        break;
      }
      if (tag.focus === 0)
        tag.rect = [l,t,w,h].map(x => Math.round(x));
      if (tag.focus === 2)
        tag.wheel = [l,t,w,h].map(x => Math.round(x));
        
      common.axles.imageDirty = true;

      if (pts && cnvs.resizeIndex !== null && cnvs.resizeIndex > -1) {
        const rect = tag.focus === 0 ? tag.rect : tag.wheel;
        const pts2 = cnvs.getPointsEx(rect);
        cnvs.hover = pts2[cnvs.resizeIndex];
      }

      cnvs.paint("pan");

    } catch (ex) {
      console.error('failed on mouse resize', ex);
    }
  }

  getBoundedRect = (r: number[]): number[] => {
    try {
      const x = Math.max(r[0], 0);
      const y = Math.max(r[1], 0);
      const size = planesService.canvas.imageSize;
      const w = Math.min(r[2], size[0] - x - 1);
      const h = Math.min(r[3], size[1] - y - 1);
      return [x,y,w,h ];
    } catch(ex) {
      console.error('failed to get bounded rect:', ex);
      return r;
      }
    }

    mouseAnnotatePoly = (evt:any) => {
      try {
        const pt = this.getBoundedPoint(planesService.canvas.getPoint(evt));
        planesService.canvas.mousePos = pt;
        planesService.canvas.paint("pan");
       
      } catch (ex) {
        console.error('failed on mouseMove');
      }
    }

  /**
   * axle annotation
   * @param evt 
   */
  mouseAnnotate = (evt: any) => {
    try {
      const pt = this.getBoundedPoint(planesService.canvas.getPoint(evt));
      planesService.canvas.mousePos = pt;
      const pt0 = this.ptRectOrigin;
      const x = Math.min(pt.x, pt0.x);
      const y = Math.min(pt.y, pt0.y);
      const w = Math.abs(pt.x - pt0.x);
      const h = Math.abs(pt.y - pt0.y);
      planesService.canvas.hotRect = [x, y, w, h];
      // limit
      planesService.canvas.hotRect = this.getBoundedRect(planesService.canvas.hotRect);
      planesService.canvas.paint("pan");
     
    } catch (ex) {
      console.error('failed on mouseAnnotate');
    }
  };



  rectContains = (r: number[], pt: any): boolean => {
    try {
      return (r[0] < pt.x && r[1] < pt.y && r[0] + r[2] > pt.x && r[1] + r[3] > pt.y);
    } catch (ex) {
      console.log('failed on check containment:', ex);
      return false;
    }
  }

  /**
   * returns tag that contains the specified point, or null if point is outside tags
   * @param pt point to check
   * @returns 
   */
  getContainingTag = (pt: any) => {
    try {
      // all existing tags
      const tags = planesService.canvas.getTags();
      if (!tags) 
        return null;

      return tags.find((t: any) => this.rectContains(t.rect, pt));
     
    } catch (ex) {
      console.log('failed to get containing tag:', ex);
    }
  } 

  inBounds = (pt: any) => {
    try {
      const size = planesService.canvas.imageSize;
      if (pt.x < 0 || pt.y < 0)
        return false;

      if (pt.x > size[0] || pt.y > size[1])
        return false;

      return true;
    } catch (ex) {
      console.log('failed to control inBounds: ', ex);
      return false;
    }
  }

  /**
   * whether annotation poly or rect
   * @returns 
   */
  polyAnnotation = () => {
    try {
      const ants = common.planes.annotations;
      if (!ants.selectedObject) return false;
      // plate is focused
      return this.ctrlKey;
    } catch (ex) {
      console.error('failed on is poly:', ex);
    }
  }

  /**
   * double click to start a new axle tag (with ctrl - a new cab tagging)
   * @param evt 
   * @returns 
   */
  doubleClick = (evt: any) => {
    try {
      const ants = common.planes.annotations;
      const pt =  planesService.canvas.getPoint(evt);
      const selected = ants.selectedObject;

      // ignore double click outside
      if (!this.inBounds(pt))
        return; 

      if (!ants.selectedObject)
        return;

      if (this.inside(pt, ants.selectedObject.poly)) {
        planesService.canvas.measureCharHeight("Start", pt);
        return;
      }
 
      if (evt.ctrlKey || evt.shiftKey || evt.altKey) {
        planesService.canvas.hotPoly = [pt];
        selected.focus = 1;
        return;
      }

      // if clicked inside - annotating a wheel,
      // otherwise, a new plane rect
      const cnvs = planesService.canvas;
      const isInside =  this.inside(pt, cnvs.getPoints(selected.rect));
      selected.focus = isInside ? 2 : 0;


      // if double click inside an existing tag - ignore
      // const containing = this.getContainingTag(pt);
      // if (containing) 
      //   return;

      this.ptRectOrigin = pt;

    } catch (ex) {
      console.error('failed on double click');
    }
  }

    /**
   * determine if a point is inside a polygon
   * @param point 
   * @param vs 
   * @returns 
   */
    inside = (point: Point, vs: Point[]) => {      
      var x = point.x, y = point.y;
      
      var inside = false;
      for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
          var xi = vs[i].x, yi = vs[i].y;
          var xj = vs[j].x, yj = vs[j].y;
          
          var intersect = ((yi > y) !== (yj > y))
              && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
          if (intersect) inside = !inside;
      }
      
      return inside;
  };

 getRect = (pt0:any, pt1: any): number[] => {
   try {
    const x = Math.min(pt0.x, pt1.x);
    const y = Math.min(pt0.y, pt1.y);
    const w = Math.abs(pt0.x - pt1.x);
    const h = Math.abs(pt0.y - pt1.y);
    return [x,y,w,h];
   } catch (ex) {
     console.error('failed to get rect:', ex);
     return [0,0,0,0];
   }
 }

  mouseUp = (evt: any) => {
    try {

      this.dragOffset = null;
      this.ctrlKey = false;

      if (this.ptRectOrigin && this.ptDrag) {
        var r = this.getRect(this.ptRectOrigin, this.ptDrag);
        common.notify('PlanesSetRect', r as any);
      }

      // after resize - normalize
      if (planesService.canvas.resizeIndex !== null) {
        const tag = common.planes.annotations.selectedObject;
        const r = tag.rect;
        // normalize rect, not poly
        if (r) {
          tag.rect = [
            Math.min(r[0],r[0] + r[2]), 
            Math.min(r[1], r[1] + r[3]),
            Math.abs(r[2]),
            Math.abs(r[3])
          ];
          }
      }

      this.ptDrag = null;
      this.ptRectOrigin = null;
      planesService.canvas.resizeIndex = null;
      planesService.canvas.hotRect = null;
      planesService.canvas.hotCabRect = null;
    
    

      // common.notify('AxleTagsChanged');

    } catch(ex) {
      console.error('failed on mouseUp: ', ex);
    }
  };

  mouseLeave = (evt: any) => {
    try {
      this.ptDrag = null;
      this.ptRectOrigin = null;
      planesService.canvas.resizeIndex = null;

    } catch (ex) {
      console.error('failed to handle mouse leave');
    }
  }

  zoom = (clicks:number) => {
    try {
        const ctx = planesService.canvas.ctx;
        const scaleFactor = 1.1;
        const pt = planesService.canvas.mousePos;
        if (!pt)
          return;
          
        ctx.translate(pt.x, pt.y);
        const factor = Math.pow(scaleFactor, clicks);
        ctx.scale(factor, factor);
        ctx.translate(-pt.x, -pt.y);
        planesService.canvas.saveMatrix();
      } catch (ex) {
        console.error('failed to zoom:', ex);
      }
    };

  mouseWheel = (evt: any) => {
    try {
      const delta = evt.nativeEvent.wheelDelta ? evt.nativeEvent.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
      this.zoom(delta);
    } catch (ex) {
      console.error('failed to handle mouseWheel:', ex);
    }
  };


}

const pan: Pan = new Pan();

export default pan;