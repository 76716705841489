import common, { PlaneItem, PlanePlate } from './commonService';
import axios from 'axios';
import { PlanesCycler } from './planesCycler';
import { PlanesCanvas } from './planesCanvas';

/**
 * planes service
 */
class PlanesService {

    cycler: PlanesCycler = new PlanesCycler();
    canvas: PlanesCanvas = new PlanesCanvas();
    

    /**
     * standard constructor
     */
    constructor() {
        common.notifier$.subscribe(msg => {
            switch(msg.name) {

                case "PlanesRecordSelected":
                    this.getRecord();
                    break;


                case 'PlanesAddPlane':
                    this.addPlane();
                    break;

                case 'PlanesDeleteObject':
                    this.deleteObject(msg.data);
                    break;

                case 'PlanesSetRect':
                    this.setRect(msg.data);
                    break;

                case 'PlanesSelectObject':
                    this.selectObject(msg.data);
                    break;

                case 'PlanesDeleteWheel':
                    this.deleteWheel(msg.data);
                    break;

                case 'PlanesDeletePlate':
                    this.deletePlate(msg.data);
                    break;

                case 'PlanesSave':
                    this.saveRecord();
                    break;

                case 'PlanesCancel':
                    this.cancelRecord();
                    break;

                case 'PlanesValidate':
                    this.validateAnnotations();
                    break;

                case 'PlanesUnzoom':
                    this.canvas.unzoom();
                    break;

                case 'PlanesDownloadImage':
                    this.downloadRawImage();
                    break;

                case 'PlanesFilterChanged':
                    this.updateFilter();
                    break;

                case 'PlanesAnnotationsChanged':
                case 'PlanesFocusChanged':
                    this.canvas.paint('planesService');
                    break;

                case 'PlanesHelp':
                    this.showHelp();
                    break;


            }
        });
    }

    /**
     * load the specified record
     * @param rec 
     */
    async getRecord() {
        try {
            const row = common.planes.selectedRow;
            const id = row.image;
            const serverUrl =  process.env.REACT_APP_SERVER_URL;
            const url = `${serverUrl}/plane/${id}`;
            const reply = await axios.get(url, {headers: {'Authorization': common.loginToken}});
            this.setAnnotationsFromNative(reply.data.plane.human);
            const token = `user-authorization=${common.loginToken}`;
            const imageUrl = `${serverUrl}/plane/${id}?${token}&raw`;
            this.canvas.setImage(imageUrl);

            common.app.context.json = JSON.stringify(reply.data, null, 2);
            common.notify('PlanesRecordLoaded');
            common.notify('PlanesValidate');

            const ants = common.planes.annotations;
            if (ants.items.length > 0) {
                this.selectObject(ants.items[0]);
            }

        
        } catch (ex) {
            console.error('failed to get plane record');
        }
    }

    /**
     * does specified rect contains an internal rect
     * @param a 
     * @param b 
     * @returns 
     */
    rectContainsRect(a:number[], b: number[]) {
        try {
            if (b[0] < a[0]) return false;
            if (b[1] < a[1]) return false;
            if (b[0] + b[2] > a[0] + a[2]) return false;
            if (b[1] + b[3] > a[1] + a[3]) return false;
            return true;
        } catch (ex) {
            console.error('failed on rectContainsRect');
            return false;
        }
    }

    /**
     * does rect contains the center of a given rect
     * @param a 
     * @param b 
     * @returns 
     */
    rectContainsCenter(a:number[], b: number[]) {
        try {
            const x = b[0] + b[2]/2;
            const y = b[1] + b[3]/2;
            if (x < a[0] || x > a[0] + a[2]) return false;
            if (y < a[1] || y > a[1] + a[3]) return false; 
            return true;
        } catch (ex) {
            console.error('failed on rectContainsCenter');
            return false;
        }
    }

    rectContainsPoly(r:number[], p: number[]) {
        try {
            for (let i of [0,2,4,6]) 
                if (p[i] < r[0] || p[i] > r[0] + r[2]) return false;

            for (let i of [1,3,5,7])
                if (p[i] < r[1] || p[i] > r[1] + r[3]) return false;
         
            return true;
        } catch (ex) {
            console.error('failed on rectContainsRect');
            return false;
        }
    }

    /**
     * set annotations from native structure
     * @param native 
     * @returns 
     */
    setAnnotationsFromNative(native:any) {
        try {
            if (!native) return;
            const ants = common.planes.annotations;
            const items = native.item?.Annotations || [];
            const planes = items.filter((a:any) => a.type === 'plane');
            const wheels = items.filter((a:any) => a.type === 'wheel');
            const plates = native.plate?.Annotations || [];
            const extras = ants.extras;
            const annotations = [];
            for (let i of planes) {
                const p = new PlaneItem();
                p.type = 'plane';
                p.direction = i.direction;
                p.rect = i.rect;

                const plate = plates.find((p:any) => this.rectContainsPoly(i.rect,p.poly));
                if (plate) {
                    p.text = plate.text;
                    p.whiteChars = plate.white_chars;
                    p.poly = this.intsToPoly(plate.poly);
                    p.extra = ants.extras[plate.extra];
                    p.charHeight = plate.char_height;
                }
                const wheel = wheels.find((w:any) => this.rectContainsCenter(i.rect, w.rect));
                if (wheel) {
                    p.wheel = wheel.rect;
                }
                annotations.push(p);
            }
            ants.items = annotations;
            ants.problematic = native.Problematic || false;
            common.notify('PlanesAnnotationsChanged');
        } catch (ex) {
            console.error('failed to set annotations:', ex);
        }
    }
    
    /**
     * standard init
     */
    async initialize() {
        try {
            this.cycler.initialize();
        } catch (ex) {
            console.error('failed to initialize planes service:', ex);
        }
    }

    /**
     *   load the specified dataset
     */
    async loadDataset(id: string, selectedEntry: string) {
        try {
            console.log(`loading dataset ${id}, ${selectedEntry} ....`);

            await common.assureLogin();

            common.mode = 'PLANES';
            common.notify('ApplicationModeChanged');
            common.app.context.json = '';
      
            common.planes.loadingDataset = true;
            common.notify('PlanesLoadStateChanged');
      
            const serverUrl =  process.env.REACT_APP_SERVER_URL;
            const seqUrl = `${serverUrl}/dataset/${id}`;
            const reply = await axios.get(seqUrl, {headers: {'Authorization': common.loginToken}});
            const dataset = reply.data;
            const sequences = dataset.sequences;
            const records = [];
            let index = 1;
            for (let seq of sequences) {
                const url = `${serverUrl}/sequence_plane/${seq}`;
                const rep = await axios.get(url,  {headers: {'Authorization': common.loginToken}});
                const images = rep.data.sequence_plane.images;
                for (let img of images) {
                    records.push({sequence: seq, image: img, state: {index}});
                    index++;
                }
            }
            common.planes.unfilteredRecords = records;
            this.cycler.reset();
            this.updateFilter();
            const alias = dataset?.dataset?.alias;
            common.plates.settings.datasetId = `${common.mode} ${id} ${alias}`;
            const settings = common.plates.settings;
            const aliasChanged = alias !== settings.datasetName;
            common.plates.settings.datasetName = alias;
            if (aliasChanged) {
                settings.tagPlanesPlane = false;
                settings.tagPlanesPlate = false;
                settings.tagPlanesWheel = false;
            }
            common.notify('SaveSettings');

            this.loadState();
            const savedId = common.planes.state.savedId;
            if (savedId && records.find(r => r.image === savedId)) {
                common.notify('PlanesSelectRecord', common.planes.state.savedId);
            } else {
                common.notify('PlanesSelectFirstRecord');
            }
        } catch (ex) {
            console.error('failed to load dataset:', ex);
        } finally {
            common.planes.loadingDataset = false;
            common.notify('PlanesLoadStateChanged');
        }
    }

      /**
     *   add a plane annotation
     */
    addPlane() {
        try {
            const ants = common.planes.annotations;
            ants.selectedObject = new PlaneItem();
            ants.selectedObject.type = 'plane';
            common.planes.annotations.items.unshift(ants.selectedObject);
            common.notify('PlaneItemsChanged');
            common.notify('PlanesValidate');
        } catch (ex) {
            console.error('failed to add plate:', ex);
        }
    }

      /**
   * add a new tag at the specified location
   * @param rect 
   */
  setRect = (rect:number[]) => {
    try {
      rect = rect.map(x => Math.round(x));
      const item = common.planes.annotations.selectedObject;
      if (!item) return;
      if (item.focus === 0)
        item.rect = rect;
      else if (item.focus === 2)
        item.wheel = rect;
    common.notify('PlanesAnnotationsChanged');
      common.notify('PlanesValidate');
    } catch (ex) {
      console.error('failed to add tag:', ex);
    }
  }


      /**
     *   delete the specified annotation
     */
    async deleteObject(obj:any) {
        try {
            common.assert(obj, 'No object to delete');
            const confirmed = await common.confirm('Delete object', 'Are you sure you want to delete selected object ?');
            if (!confirmed) return;
            let next = null;
            const ants = common.planes.annotations;
           
            let index = ants.items.indexOf(obj);
            if (index > -1) {
                ants.items = ants.items.filter(i => i !== obj);
                index = Math.min(index, ants.items.length - 1);
                ants.selectedObject = ants.items[index];
            }
            common.notify('PlanePlatesChanged');
            common.notify('PlanesValidate');
        } catch (ex) {
            console.error('failed to delete object:', ex);
        }
    }

    async deleteWheel(obj:any) {
        try {
            common.assert(obj, 'No object to delete');
            const confirmed = await common.confirm('Delete wheel', 'Are you sure you want to delete wheel annotation ?');
            if (!confirmed) return;
   
            obj.wheel = [];
            common.notify('PlanesAnnotationsChanged');
        } catch (ex) {
            console.error('failed to delete wheel:', ex);
        }
    }

    async deletePlate(obj:any) {
        try {
            common.assert(obj, 'No object to delete');
            const confirmed = await common.confirm('Delete plate', 'Are you sure you want to delete plate annotation ?');
            if (!confirmed) return;

            obj.poly = [];
            common.notify('PlanesAnnotationsChanged');
        } catch (ex) {
            console.error('failed to delete wheel:', ex);
        }
    }

    async showHelp() {
        try {
            } catch (ex) {
            console.error('failed to show help:', ex);
        }
    }

    /**
     *   select the specified annotation
     */
    selectObject(obj:any) {
        try {
            const ants = common.planes.annotations;
            ants.selectedObject = obj;
            this.canvas.paint('planesService');
            common.notify('PlanesSelectedObjectChanged');

        } catch (ex) {
            console.error('failed to select object:', ex);
        }
    }

    /**
     *   save record
     */
    async saveRecord() {
        try {
            const native = this.getNativeRecord();
            common.assert(native, 'failed to get native plane');

            const row = common.planes.selectedRow;
            const id = row.image;

            common.planes.state.savedId = id;
            this.saveState();

            const token = common.loginToken;
            const serverUrl =  process.env.REACT_APP_SERVER_URL;
            const url = `${serverUrl}/plane/${id}`;
            const reply = await axios.get(url, {headers: {'Authorization': common.loginToken}});
            const data = reply.data;
            data.plane.human = native;
            const json = JSON.stringify(native, null, 2);
            const reply2 = await axios.put(url, data,  {headers: {'Authorization': common.loginToken}});
            common.notify('PlanesSelectNextRecord');
            return true;
        } catch (ex) {
            const msg = (ex as any).message;
            console.error('failed to save record:', ex);
        }
    }

    /**
     * convert poly points to ints
     * @param poly 
     * @returns 
     */
    polyToInts(poly:any[]) {
        try {
            const ints = [];
            for(let p of poly) {
                ints.push(Math.round(p.x));
                ints.push(Math.round(p.y));
            } 
            return ints;
        } catch (ex) {
            console.error('failed on polyToInts:', ex);
        }
    }

    intsToPoly(a:number[]) {
        try {
            if (a.length !== 8) return [];
            return [{x:a[0],y:a[1]},{x:a[2],y:a[3]},{x:a[4],y:a[5]},{x:a[6],y:a[7]}];

        } catch (ex) {
            console.error('failed on intsToPoly:', ex);
            return [];
        }
    }

      /**
     *   returns a native record from internal structure
     */
    getNativeRecord() {

        try {
            const ants = common.planes.annotations;
            const native:any = {};
            native.item = {Annotations:[]};
            native.plate = {Annotations:[]};
            native.Problematic = ants.problematic || false;
            for(const item of ants.items) {
                if (item.rect.length === 4)
                native.item.Annotations.push({
                    rect: item.rect,
                    type: "plane",
                    direction: item.direction
                });
                if (item.wheel.length === 4) 
                    native.item.Annotations.push({
                    rect: item.wheel,
                    type: "wheel",
                    direction: item.direction
                });
                if (item.poly.length === 4) {
                         const extraIndex = ants.extras.indexOf(item.extra);
                         native.plate.Annotations.push({
                        poly: this.polyToInts(item.poly),
                        char_height: item.charHeight,
                        white_chars: item.whiteChars,
                        text: item.text,
                        extra: extraIndex
                    });
                }
            }
            return native;
        } catch (ex) {
            console.error('failed to get native record:', ex);
        }
    }

    /**
     *   cancel changes
     */
    async cancelRecord() {
        try {
            const confirmed = await common.confirm('Cancel', 'Are you sure you want to cancel changes ?');
            if (!confirmed) return;

            this.getRecord();

        } catch (ex) {
            console.error('failed to cancel record:', ex);
        }
    }

    /**
     *  validate annotation
     */
    validateAnnotations() {
        try {
            const msg = this.getValidationMessage();
            if (common.planes.validationMessage !== msg) {
                common.planes.validationMessage = msg;
                common.notify('PlanesValidationChanged');
            }

        } catch (ex) {
            console.error('failed to validate annotation:', ex);
        }
    }

    /**
     * get validation message
     * @returns 
     */
    getValidationMessage() {
        try {
            const ants = common.planes.annotations;
            common.assert(ants, 'no plane ants');
            for (let i of ants.items) {
                if (!i.type) return 'Item type not set';
                if (i.rect.length !== 4) return `${i.type} rect not set`;
                if (!i.direction) return `${i.type} direction not set`;
            }
           
            return '';
        } catch (ex) {
            console.error('failed to get validation message:', ex);
            return '';
        }
    }

    /**
     * save current image as jpg
     */
    async downloadRawImage() {
        try {
          const FileSaver = require('file-saver');
          const serverUrl =  process.env.REACT_APP_SERVER_URL;
          const row = common.planes.selectedRow;
          const id = row.image;
          const imageUrl =`${serverUrl}/plane/${id}?${common.imageToken}&raw`
          FileSaver.saveAs(imageUrl,`${id}.jpg`);
        } catch (ex) {
          console.error('failed to download raw image:', ex);
        }
    }

       /**
   * cache state in local storage
   */
  saveState = () => {
    try {
      const state = common.planes.state;
      const item = JSON.stringify(state);
      localStorage.setItem('planes.state', item);
    } catch (ex) {
      console.error('failed to save state:', ex);
    }
  }

  /**
   * load cached state from local storage
   */
  loadState = () => {
    try {
      const item = localStorage.getItem('planes.state');
      if (item) {
        const state = JSON.parse(item);
        common.planes.state = state;
      }

    } catch (ex) {
      console.error('failed to load state:', ex);
    }
  }

  passedFilter(rec:any) {
    try {
        const f = common.planes.filter;
        if (f.problematic === true && rec.state?.problematic === false) return false;
        if (f.problematic === false && rec.state?.problematic === true) return false;
        if (f.planes === true && rec.state?.planes === 0) return false;
        if (f.planes === false && rec.state?.planes > 0) return false;
        if (f.plates === true && rec.state?.plates === 0) return false;
        if (f.plates === false && rec.state?.plates > 0) return false;
        if (f.wheels === true && rec.state?.wheels === 0) return false;
        if (f.wheels === false && rec.state?.wheels > 0) return false;
      return true;
    } catch(ex) {
        console.error('failed on passed filter:', ex);
    }
  }

  /**
   * update records filter
   */
  updateFilter() {
    try {
        const planes = common.planes;
        planes.records = planes.unfilteredRecords.filter(r => this.passedFilter(r));
        common.notify('PlanesRecordsChanged');
        
    } catch (ex) {

    }
  }
}
const planesService: PlanesService = new PlanesService();
export default planesService;
