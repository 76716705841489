import { countReset } from "console";
import { isTypeReferenceNode, parseJsonConfigFileContent } from "typescript";
import { ScanPolicy } from "./scanPolicy"

export class PlatesStateData {
  datasetId: string = '';
  // last record saved
  savedRecordId: string = '';
  bookmarks: string[] = [];
}



export class FilterSettings {
  tagged: boolean | null = true;
  pretagged: boolean | null = true;
  problematic: boolean | null = null;
  marked: boolean | null = null;
  insides:  boolean | null = null;
  canFilter: boolean = false;
  // dataset contains bccm
  bccm: boolean | null = null;
  vehicles: boolean | null = null;
  lights: boolean | null = null;
  hazards: boolean | null = null;
  reflectives: boolean | null = null;

  includesVehicles: boolean = true;
  match: boolean | null = null;
  occluded: boolean | null = null;
  chars: boolean | null = null;
  
  // show bccm filter
  bccmFilter: boolean = false;
  search: string = '';
  searchId: string = '';
  searchSeqId: string = '';


  // all vehicles
  vehicleType: number = -1;
  plateType: number = -1;
  vehicleTypes: any[] = [];
  plateTypes: any[] = [];


  extraType: number = -1;
  extraTypes: any[] = [];

  reset = () => {
    this.tagged = null;
    this.pretagged = null;
    this.problematic = null;
    this.marked = null;
    this.bccm = null;
    this.vehicles = null;
    this.insides = null;
    this.occluded = null;
    this.chars = null;


    this.vehicleType = -1;
    this.plateType = -1;
    this.vehicleTypes.forEach(v => v.count = 0);
    this.plateTypes.forEach(v => v.count = 0);
    this.extraTypes.forEach(v => v.count = 0);
  
  }
}
export class PlatesSettings {
  mode: string;
  filterFaces: boolean;
  defaultNation: string | null;
  showSettings: boolean;
  showLanes: boolean;
  showPlate: boolean = false;
  preTagging: boolean;
  tooltips: boolean = true;
  debug: boolean = false;
  metadataKeys: string = '';
  showPlatesFilter: boolean = false;
  pureImageLib: boolean = true;
  arabicChars: boolean = true;
  region: string = '';

  tagLights: boolean = false;
  tagInside: boolean = false;
  tagOcr: boolean = false;
  tagVehicle: boolean = false;
  tagHazard: boolean = false;
  tagChars: boolean = false;
  tagAxle: boolean = false;
  tagCab: boolean = false;
  tagReflective: boolean = false;
  axlesStitchTrim: number = 0;
  // current dataset name
  datasetName: string = '';
  // last dataset used and it's type
  datasetId: string = '';
  // last selected item id (used for reloading)
  datasetSelectedItemId: string = '';
  auditMode: boolean = false;

  orderBy: boolean = false;
  orderField0: string = '';
  orderField1: string = '';

  tagPlanesPlane: boolean = false;
  tagPlanesPlate: boolean = false;
  tagPlanesWheel: boolean = false;


  constructor() {
    this.mode = 'Annotation';
    this.filterFaces = true;
    this.defaultNation = null;
    this.showSettings = false;
    this.showLanes = false;
    this.preTagging = true;
  }

}

export class InsideEntry {
  debugIndex: number = -1;
   hoverIndex: number = -1;
   selectDriver: boolean = false;
  windshield: CompoundString = new CompoundString();
  windshieldStatus: string = 'tagged';
  passengers:InsideItem[][] = [
    [new InsideItem(),new InsideItem(),new InsideItem()],
    [new InsideItem(),new InsideItem(),new InsideItem()]
  ]

  selectedPassenger?: InsideItem;


  getPassengers () {
    const ps = this.passengers
    return [ps[0][0], ps[0][1], ps[0][2], ps[1][0], ps[1][1], ps[1][2]];
  }

  getPassengersClockwise()  {
    const ps = this.passengers
    return [ps[1][2], ps[1][1], ps[1][0], ps[0][0], ps[0][1], ps[0][2]];
  }

  getPassengersCounterClockwise()  {
    const ps = this.passengers
    return [ps[1][0], ps[1][1], ps[1][2], ps[0][2], ps[0][1], ps[0][0]];
  }

  // get native structure
  getNative() {
    try {

      const ints = this.windshield.value?.split(',').map(s => parseInt(s));
      const native = {
        windshield: {
          topleft_x: ints[0],
          topleft_y: ints[1],
          topright_x: ints[2],
          topright_y: ints[3],
          bottomright_x: ints[4],
          bottomright_y: ints[5],
          bottomleft_x: ints[6],
          bottomleft_y: ints[7],
          status: this.windshieldStatus || 'tagged'
        },
      passengers: this.getNativePassengers()
    }
    return native;
  } catch (ex) {
    console.error('failed to get native inside:', ex);
  }
    // 01/06/2022 specified
    // if (!this.middleEnabled) {
    //   native.middle.occupied.value = 'X';
    //   native.middle.driver.value = 'X';
    //   native.middle.belt.value = 'X';
    //   native.middle.phone.value = 'X';
    // }
  }

  getNativePassengers() {
    try {
      const passengers = [];
      const positions = [['rear_left', 'rear_center', 'rear_right'],['front_left','front_center','front_right']];
      for (let i = 0; i < this.passengers.length; i++) {
        for (let j = 0; j < this.passengers[i].length; j++) {
          const passenger = this.passengers[i][j];
          if (!passenger.occupied.value)
            continue;

          
          const r0 = passenger.faceRect.value;
          let left = r0[0] || 0;
          let top = r0[1] || 0;
          let right = left + r0[2] || 0;
          let bottom = top + r0[3] || 0;

          if (!passenger.faceVisible.value) {
            left = 0;
            top = 0;
            right = 0;
            bottom = 0;
          }

          if (passenger.occupied) {
            const p = {
              position: positions[i][j],
              driver: passenger.driver.value,
              belt: {visible: passenger.beltVisible.value, fastened: passenger.beltFastened.value},
              phone: {visible: passenger.phoneVisible.value, in_use: passenger.phoneUsed.value},
              face: { topleft_x: left, topleft_y: top, bottomright_x: right, bottomright_y: bottom, 
                visible: passenger.faceVisible.value},
              status: passenger.status || 'tagged',
            }
            passengers.push(p);
          }
        }
      }
      return passengers;
    } catch (ex) {
      console.error('failed to get native passengers:', ex);
    }
  }
}

export class InsideItem {
  occupied: CompoundBool = new CompoundBool();
  driver: CompoundBool = new CompoundBool();
  beltVisible: CompoundBool = new CompoundBool();
  beltFastened: CompoundBool = new CompoundBool();
  phoneVisible: CompoundBool = new CompoundBool();
  phoneUsed: CompoundBool = new CompoundBool();
  faceVisible: CompoundBool = new CompoundBool();
  faceRect: CompoundRect = new CompoundRect();
  hoverIndex: number = -1; 
  status: string = 'tagged';
  getNative() {
    return {
      occupied: this.occupied.getNative(), 
      driver: this.driver.getNative(), 
      belt: this.beltVisible.getNative(), 
      phone: this.phoneVisible.getNative()
    }
  }

  notTagged() {
    const tagged = this.driver.value || this.faceVisible.value || this.beltVisible.value || this.phoneVisible.value;
    return this.occupied.value && !tagged;
  }
}



export class CompoundBool {
  value: boolean = false;
  // default value when created
  status: string = 'tagged';
  getNative() {
    return {value: this.value ? '1' : '0', status: this.status};
  }
}

export class CompoundString {
  value: string = '';
  status: string = 'tagged';
}

export class CompoundRect {
  value: number[] = [];
  // default value when created
  status: string = 'tagged';
  getNative() {
    return {value: this.value ? '1' : '0', status: this.status};
  }
}


export class VehicleBounding {
  // ofer 31/01/2022
  rect: ValueStatus = {status: 'tagged', value: [0,0,0,0]}; 
  pose: ValueStatus = {status: 'tagged', value: "F"};
  hoverIndex: number = -1;
  bccmIndex: ValueStatus = {status: 'tagged', value: -1};
  plateIndices: ValueStatus = {status: 'tagged', value: []};
  // WTT-441
  VehicleType:ValueStatus = {status: 'tagged', value: 0};
}

export class LightsBounding {
  // left, top, width, height
  rect:number[] = [0,0,0,0];
  lights: LightItem[] = [];
  hoverIndex: number = -1;
}

export class HazardData {
  rect: number[] = [0,0,0,0];
  id: string = '';
  class: string = '';
  text: string = '';
  un: string = '';
  hoverIndex: number = -1;
  ExtraType: number = 0;
}

export class ReflectiveData {
  rect: number[] = [0,0,0,0];
  type: string = '';
  hoverIndex: number = -1;
}


export class LightItem {
  rect: number[] = [0,0,0,0];
  type: string = '';
  status: string = 'off';
  hoverIndex: number = -1;
}

export enum PlateMatch {
  None,
  NoPlate,
  NoAnnotations,
  NoMatch,
  Match
}


export class StatisticsEntry {
  id: string = '';
  data: boolean = false;
  tags: boolean = false;
  pretags: boolean = false;
  problematic: boolean = false;
  vehicleTypes: number[] = [];
  plateTypes: number[] = [];
  nations: string[] = [];
  bccm: any = null;
  inside: any = null;
  hazards: any = null;
  vehicleBoxing: boolean = false;
  headerPlateMatched: PlateMatch = PlateMatch.None;
  split?: string;
  // WTT-421 - number of annotations with chars tags
  chars: number = 0;
  year: number = 0;
  // WTT-415 
  lights: number[] = [0,0,0,0,0];
}

export class NationStatistics {
  nation: string = '';
  count: number = 0;
  percentage: number = 0;
  title: string = '';
}

export class YearStatistics {
  year: string = '';
  count: number = 0;
  percentage: number = 0;
  title: string = '';
}

export class HazardStatistics {
  name: string = '';
  count: number = 0;
  percentage: number = 0;
  title: string = '';
}



export class StatisticsInfo {
  entries: StatisticsEntry[] = [];
  totalEntries: number = 0;
  totalTagged: number = 0;
  totalPretagged: number = 0;
  totalProblematic: number  = 0;
  percentage: number = 0;
  totalNotTagged: number = 0;
  axlesCounts: number[] = [];
  axles: boolean = false;
  // WTT-444
  planes: boolean = false;
  nations: NationStatistics[] = [];
  years: YearStatistics[] = [];
  hazards: HazardStatistics[] = [];
  totalChars: number = 0;
  totalNoChars: number = 0;
  totalBoxedVehicles: number = 0;
  totalNotBoxedVehicles: number = 0;
  totalPlateMatched: number = 0;
  totalPlateNotMatched: number = 0;
  totalPlateNoPlate: number = 0;
  totalPlateNoAnnotations: number = 0;
  totalInsides: number = 0;
  insideTagged: number = 0;
  insideTaggedPercentage: number = 0;
  totalBelts: number = 0;
  totalPhones: number = 0;
  totalPassengers: number = 0;
  // distribution of occupations (0,1,2,3 positions occupied in front seat)
  totalOccupations: number[] = [0,0,0,0,0,0,0];
  // distribution of driver positions (left, middle, right)
  totalDriverPositions: number[] = [0,0,0];
  totalLights: number = 0;
  // WTT-415 none, red, yellow, green, multiples
  lights: number[] = [0,0,0,0,0];



  splits: number[] = [0,0,0,0];

  totalBccmColors: number = 0;
  totalBccmMakers: number = 0;
  customs: string[] = ['Nations'];
  selectedCustom?: string = 'Nations';
  // vehicle type statistics
  totalTags: number = 0;
  // bccm
  makersPie: any[] = [];
  makers10: any[] = [];
  // max percentage * 2 
  makersWidth: number = 0;
  platesWidth: number = 0;
  // bccm
  colorsPie: any[] = [];
  vehiclesPie: any[] = [];
  platesPie: any[] = [];
  splitsPie: any[] = [
    { name: 'train',title: 'train', value: 0, color: 'orange' },
    { name: 'val', title: 'val', value: 0, color: 'cyan' },
    { name: 'test', title: 'test', value: 0, color: 'magenta' },
    { name: 'none', title: 'none', value: 0, color: 'gray' },
    ]
  taggingPie: any[] = [
    { name: 'Not tagged',title: 'Not tagged', value: 0, color: 'gray' },
    { name: 'Tagged', title: 'Tagged', value: 0, color: 'green' },
    { name: 'PreTagged', title: 'Pretagged', value: 0, color: 'orange' },
    { name: 'Problematic', title: 'Problematic', value: 0, color: 'red' },
    ]

    // vehicle box
    boxingPie: any[] = [
      { name: 'Not tagged',title: 'Not tagged', value: 0, color: 'gray' },
      { name: 'Tagged', title: 'Tagged', value: 0, color: 'green' },
      ]

    charsPie: any[] = [
        { name: 'Not tagged',title: 'Not tagged', value: 0, color: 'gray' },
        { name: 'Tagged', title: 'Tagged', value: 0, color: 'green' },
        ]

  sequencePie: any[] = [
    { name: 'Not tagged',title: 'Not tagged', value: 0, color: 'gray' },
    { name: 'Tagged', title: 'Tagged', value: 0, color: 'green' },
    { name: 'Problematic', title: 'Problematic', value: 0, color: 'red' },
  ];

  auditPie: any[] = [
    { name: 'Not audited',title: 'Not audited', value: 0, color: 'gray' },
    { name: 'Passed', title: 'Passed', value: 0, color: 'green' },
    { name: 'Failed', title: 'Failed', value: 0, color: 'red' },
  ];

  bccmPie: any[] = [
    { name: 'No BCCM', title: 'No BCCM', value: 0, color: 'gainsboro' },
    { name: 'Not tagged',title: 'Not tagged', value: 0, color: 'gray' },
    { name: 'Tagged', title: 'Tagged', value: 0, color: 'green' },
  ];

  accuracyPie: any[] = [
    { name: 'No plate',title: 'No plate', value: 0, color: 'gray' },
    { name: 'No annotations', title: 'No annotations', value: 0, color: 'orange' },
    { name: 'Not matched', title: 'Not matched', value: 0, color: 'red' },
    { name: 'Matched', title: 'Matched', value: 0, color: 'green' },
    ]

  beltPie: any[] = [
    { name: 'No belt',title: 'No belt', value: 0, color: 'gray' },
    { name: 'Belt', title: 'Belt', value: 0, color: 'green' },
    ]

  phonePie: any[] = [
    { name: 'No phone',title: 'No phone', value: 0, color: 'gray' },
    { name: 'Phone', title: 'Phone', value: 0, color: 'green' },
    ]

    occupationPie: any[] = [
      { name: '0',title: 'None', value: 0, color: 'red' },
      { name: '1', title: '1', value: 1, color: '#002A00' },
      { name: '2', title: '2', value: 2, color: '#005400' },
      { name: '3', title: '3', value: 3, color: '#007E00' },
      { name: '4', title: '4', value: 3, color: '#00A800' },
      { name: '5', title: '5', value: 3, color: '#00D200' },
      { name: '6', title: '6', value: 3, color: '#00FF00' },
    ]

    lightsPie: any[] = [
      { name: 'Off', title: 'Off', value: 0, color: 'gainsboro' },
      { name: 'Red',title: 'Red', value: 0, color: 'red' },
      { name: 'Yellow',title: 'Yellow', value: 0, color: 'yellow' },
      { name: 'Green',title: 'Green', value: 0, color: 'green' },
      { name: 'Multiple',title: 'Multiple', value: 0, color: 'blue' },
    ];

  axlesPie: any[] = [];

     // vehicle box
     planesProblematicPie: any[] = [
        { name: 'Not problematic',title: 'Not problematic', value: 0, color: 'gray' },
        { name: 'Problematic', title: 'Problematic', value: 0, color: 'red' },
      ]

      planesExtraPie: any[] = [
        { name: 'None',title: 'None', value: 0, color: 'gray' },
        { name: 'Occluded', title: 'Occluded', value: 0, color: 'Orange' },
        { name: 'Ambiguous', title: 'Ambiguous', value: 0, color: 'Yellow' },
      ]
  



  toHex = (d:number) => {
    return  ("0"+(Number(d).toString(16))).slice(-2).toUpperCase()
}

  createAxlesPie = () => {
    try {
      const p: any[] = [];
      for (let i = 0; i < 12; i++) {
        p.push({
          name: i.toString(),
          title: i.toString(),
          value: 0,
        })
      }
      this.axlesPie = p;
    } catch (ex) {
      console.error('failed to create axlesPie:', ex);
    }
  }

  constructor() {
    this.createAxlesPie();
  }

  
}

// ofer 31/01/2022 - value is any
export class ValueStatus  {
  status: string = '';
  value: any = '';
};

export class BccmAnnotation {
  car_id: ValueStatus = { status: '', value: ''};
  car_ignore: ValueStatus = { status: '', value: ''};
  car_starred: ValueStatus = { status: '', value: ''};
  car_pos_x: ValueStatus = { status: '', value: ''};
  car_pos_y: ValueStatus = { status: '', value: ''};
  car_category: ValueStatus = { status: '', value: ''};
  car_category_human: ValueStatus = { status: '', value: ''};
  car_make: ValueStatus = { status: '', value: ''};
  car_make_human: ValueStatus = { status: '', value: ''};
  car_model: ValueStatus = { status: '', value: ''};
  car_model_human: ValueStatus = { status: '', value: ''};
  car_color: ValueStatus = { status: '', value: ''};
  car_color_human: ValueStatus = { status: '', value: ''};
  

}

export class BccmData {
  image_id: string = '';
  image_path: string = '';
  image_filename: string = '';
  image_is_color: string = 'False';
  image_day_shot: string = 'False';
  image_inplane_rotation_angle: string = '0.0';
  image_view: string = 'frontal';
  Annotations: BccmAnnotation[] = [];
}

export class DatasetsFilter {
  nameFilter: string = '';
  countries: string[] = ["ALL"];
  country: string = 'ALL';
}

export class TreeNode {
  name: string = '';
  id: string = '';
  datasets: any[] = [];
  nodes: TreeNode[] = [];
  populated: boolean = false;
  expanded: boolean = false;
}




export class PlatesData {
  selectedRecord: any;
  dbg: any;
  vehicleTypes: any[];
  plateTypes: any[];
  records: any[];
  unfiltered: any[] = [];
  selectedRow: any;
  settings: PlatesSettings;
  nations: string[];
  arabicNations: string[] = [];
  latinToArabic: any[] = [];
  latinToIranian: any[] = [];
  latinToSau: any[] = [];
  latinToOman: any[] = [];
  extraTypes: string[][];
  selectedAnnotation: any;
  loadingRecord: boolean;
  loadingDataset: boolean;
  plateFacesFilter: any;
  plateFaces: any[];
  filteredFaces: string[][];  
  catTypes: string[];
  generatingPlates: boolean;
  selectedTab: number = 0;
  about: string | null;
  totalRecords: number;
  taggedRecords: number;
  validatedRecords: number;
  gridApi: any;
  dbgText: string = '';
  statistics: string = '';
  metadatas: string[] = [];
  filterExpression: string = '';
  annotationGuide: string = '';
  validationGuide: string = '';
  infoText: string = '';
  datasetsGridApi: any = null;
  datasets: any[] = [];
  unfilteredDatasets: any[] = [];
  nativeRecord: any = null;
  abortItemStates: boolean = false;
  itemStatesPercentage: number = 0;
  currentScanningPosition: number = 0;
  saving:boolean = false;

  datasetAlias: string = '';
  datasetId: string = '';
  regions: any[] = [];
  panelWidth: number = 0;
  panelHeight: number = 0;
  detailsWidth: number = 0;
  editorsHeight: number = 0;
  showStatusProgress: boolean = true;
  showStatisticsPanel: boolean = false;
  getDatasetsPercentage: number = 0;
  gettingDatasets: boolean = false;
  abortGettingDatasets: boolean = false;

  poseTypes: string[][] = [['front','Front'], ['rear', 'Rear'], ['full','Full'], ['out_of_view','Out of view']];
  

  scanPolicy: ScanPolicy = new ScanPolicy();
  state: PlatesStateData = new PlatesStateData();
  stats: StatisticsInfo = new StatisticsInfo();
  datasetsFilter: DatasetsFilter = new DatasetsFilter();
 

  // ofer 08/06/2021 - max stack size error
  painting: boolean = false;
  paintCounter: number = 0;
  statisticsPercentage: number = 0;

  statisticsPendingAbort: boolean = false;
  statisticsState: string = 'none';
  statisticsPendingId: string = '';

  filter: FilterSettings = new FilterSettings();

  // per abilitare o meno il pulsante next
  annotationsAreValid: boolean = true;
  validationError: string | null = null;
  bccmMode: boolean = false;

  bccm: any = null;
  newTagType: string = '';
  vehicles: VehicleBounding[] = [];
  lights: LightsBounding[] = [];
  // cut & paste lights geometry
  clipboardLights: LightsBounding[] = [];


// WTT-414
hazards: HazardData[] = [];
selectedHazard?: HazardData;
reflectives: ReflectiveData[] = [];
selectedReflective?: ReflectiveData;

  selectedVehicle?: VehicleBounding;
  selectedLights?: LightsBounding;
  insides: InsideEntry[] = [];
  selectedInside?: InsideEntry;
  // WTT-388
  insideGain: number = 0;
  
  
  headerPlateNumber: string = '';

  ironIds: string[] = []; 
  datasetHash: number = 0;

  // WTT-181
  datasetsRoot: TreeNode = new TreeNode();
  rawDatasets: any[] = [];
  filteredDatasets: any[] = [];
  // pending to for which to populate datasets
  pendingNode: string = '';
  // populating tree nodes
  populatingNodes: boolean = false;
  datasetsDialogOpen: boolean = false;
  // search tree
  treeFilter: string = '';

  thaiProvinces: ProvinceData[] = [];

  // WTT-239
  getttingRecord: boolean = false;

  // 
  charPageUrls: any = { 
    SAU: 'data/chars/sau.html',
    EGY: 'data/chars/egy.html', 
    IRQ: 'data/chars/irq.html', 
    IRN: 'data/chars/irn.html', 
    OMN: 'data/chars/omn.html',
    MAR: 'data/chars/mar.html'};
  charPageUrl: string = '';

  // current audit file loaded
  auditFile?: string;
  auditLoading: boolean = false;
  auditSaving: boolean = false;

  // WTT-363 
  previousSelectionId: string = '';
  handlingSelectionChanged: boolean = false;
  skipChangesCheck: boolean = false;
  changeConfirmation?:boolean;
  reverting: boolean = false;

  // WTT-421
  selectedCharIndex: number = -1;
  charHoverIndex: number = -1;
  editedChars: any[] = [];

  constructor() {
    this.selectedRecord = null;
    this.vehicleTypes = [];
    this.extraTypes = [];
    this.records = [];
    this.selectedRow = null;
    this.settings = new PlatesSettings();
    this.nations = [];
    this.selectedAnnotation = null;
    this.plateTypes = [];
    this.loadingRecord = false;
    this.loadingDataset = false;
    this.plateFacesFilter = null;
    this.plateFaces = [];
    this.filteredFaces = [];
    this.catTypes = [];
    this.dbg = null;
    this.generatingPlates = false;
    this.selectedTab = -1;
    this.about = null;
    this.totalRecords = 0;
    this.taggedRecords = 0;
    this.validatedRecords = 0;
    this.gridApi = null;
 

  }

}

export class ProvinceData {
  province: string = '';
  abbreviation: string = '';
  iso: string = '';
  localName: string = '';
  region: string = '';

}