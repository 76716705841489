import { AgGridReact } from 'ag-grid-react';
import React, {useState, useEffect} from 'react';
import { Box, CircularProgress, Typography } from "@material-ui/core";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import common from '../../services/commonService';
import { PlanesFilter } from './planesFilter';


export function RecordList(props: any) {

    // const [gridApi, setGridApi] = useState(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  
    const [value, setValue] = useState(0); // integer state

    const problematicFormatter = (params:any) => {
      const state = params.data.state;
      const problematic = state?.problematic || false;
      return problematic ? '✓' : '';
    }
  
    const planeFormatter = (params:any) => {
      const state = params.data.state;
      const planes = state?.planes || 0;
      return planes ? planes.toString() : '';
    }

    const indexFormatter = (params:any) => {
      const state = params.data.state;
      if (state?.index !== undefined)
        return (state.index).toString();
      return '';
    }

  
    const columnDefs = [
      {headerName: 'Index', width: 100,
        field: 'state', valueFormatter: indexFormatter
       },
     {headerName: 'Problematic', width: 100,
      field: 'state', valueFormatter: problematicFormatter
     },

     {headerName: 'Planes', width: 150,
      field: 'state', valueFormatter: planeFormatter
     },

    ];
  

  
    const handleSelection = (e: any) => {
      try {
        // disable selection while loading plate
   
  
        const selectedRows = e.api.getSelectedRows();
        if (selectedRows.length === 0)
          return;
          
        const selectedRow = selectedRows[0];
        // WTT-363
        common.planes.selectedRow = selectedRow;
        common.notify("PlanesRecordSelected", selectedRow.id);
      } catch (ex) {
        console.error('failed on handle selection');
      }
    }

    const gotoNextRecord = () => {
      try {
        const api = common.planes.gridApi;
        if (api == null) return;
  
        const selectedRow = common.planes.selectedRow;
        let currentNode:any = null;
        api.forEachNode((node:any) => {if (node.data === selectedRow) currentNode = node });
        if (!currentNode) throw(new Error('failed to find current node'));
        api.forEachNode((node:any)=> node.rowIndex === currentNode.rowIndex + 1 ? node.setSelected(true) : node.setSelected(false));
      } catch (ex) {
        console.error('failed to gotoNext:', ex);
      }
    }
  
    /**
     * Select the first record
     */
    const selectFirstRecord = () => {
      try {
        setTimeout(() => {
          const api = common.planes.gridApi;
          if (api) 
            api.forEachNode((node:any)=> node.rowIndex == 0  ? node.setSelected(true) : node.setSelected(false))
        }, 100);
      } catch (ex) {
        console.error('failed to select first row:', ex);
      }
    }
  
    function onGridReady(params:any) {
      // setGridApi(params.api);
      common.planes.gridApi = params.api;
  }
  

    const handleCallbacks = (key: string, value: any) => {
      try {
        switch(key) {

          case 'PlanesLoadStateChanged':
            setValue(value => value + 1);
            break;

          case 'PlanesRecordsChanged':
            common.planes.gridApi.refreshRows();
            setValue(value => value + 1);
            break;

          case 'PlanesSelectFirstRecord':
            selectFirstRecord();
            break;

          case 'PlanesSelectNextRecord':
            gotoNextRecord();
            break;

          case 'PlanesSelectRecord':
            selectRecord(value);
            break;

          case 'PlanesRefreshRecords':
            refreshCells();
            break;
    
        }
  
      } catch (ex) {
        console.error('failed to handle callbacks:', ex);
      }
  
    }

    const refreshCells = () => {
      try {
        const api = common.planes.gridApi;
        api.refreshCells();
      } catch (ex) {

      }
    }

    /**
     * select record by imageId
     * @param id 
     */
    const selectRecord = (id: any) => {
      try {
        const api = common.planes.gridApi;
        api.forEachNode((node:any)=> node.data.image === id ? node.setSelected(true) : node.setSelected(false));
        api.forEachNode((node:any)=> {if (node.data.image === id) api.ensureNodeVisible(node, 'middle')});
        
        common.notify("LoadPlate", id);
      } catch (ex) {
        console.error('failed to handle plate selection')
      }
    }
  
  
    useEffect(() => {
      const subscription = common.notifier$.subscribe(msg => {
        handleCallbacks(msg.name as string, msg.data);
      });
      return (() => {
        subscription.unsubscribe();
      });
     
    });
  
  
  const records = common.planes.records || [];
  const loading = common.planes.loadingDataset;
  
    return (
      <Box display="flex" flex="1" flexDirection="column" >
      
               <PlanesFilter/>
                <div
                  className="ag-theme-balham"
                  style={{ height: '100%', width: '500px' }}
              >
                  <AgGridReact
                      rowHeight={32}
                      rowSelection={'single'}
                      onGridReady={onGridReady}
                      columnDefs={columnDefs}
                      onSelectionChanged={ (e:any) => handleSelection(e)}
                      rowData={records}>
                </AgGridReact>
  
              </div>
        </Box>
    );
  
  }