import common from "./commonService";
import axios from "axios";

export class PlanesCycler {
    ticking: boolean = false;
    index: number = 0;
    percentage: number = 0;
    cycled:  boolean = false;

    constructor() {
        console.log('constructed');
    }
    
    initialize() {
        try {
            setInterval(() => this.tick(), 100)
        } catch (ex) {
            console.error('failed to init plane cycler:', ex);
        }
    }

    reset() {
        try {
            this.index = 0;
            this.percentage = 0;
            this.cycled = false;
        } catch (ex) {
            console.error('failed to reset planes cycler:', ex);
        }
    }

    /**
     * tick del cycler - execute record state query
     */
    async tick() {
        try {
            if (this.ticking) return;
            const planes = common.planes;
            const recs = planes.unfilteredRecords || [];
            if (recs.length === 0) return;
            this.index = this.index % recs.length;
            const rec = recs[this.index];
            if (!rec) return;
            this.ticking = true;
            const id = rec.image;
            const serverUrl =  process.env.REACT_APP_SERVER_URL;
            const url = `${serverUrl}/plane/${id}`
            const reply = await axios.get(url, {headers: {'Authorization': common.loginToken}});
            rec.state = this.getState(reply.data, this.index);

            // coverage percentage
            const percentage = Math.round(100 * (this.index + 1)/recs.length);
            this.percentage = Math.max(this.percentage, percentage);
            if (this.index + 1 === recs.length)
                this.cycled = true;
            
            // refresh periodically
            if (this.index % 10 === 0)
                common.notify('PlanesRefreshRecords');

            this.index = this.index + 1;
            this.ticking = false;
        } catch (ex) {
            console.log('failed on planes cycler tick:', ex);
            this.ticking = false;
        }
    }

    getState(native:any, index: number) {
        try {
            const h = native.plane.human;
            const items = h.item?.Annotations || [];
            const problematic = h.Problematic || false;
            const planes = items.filter((i:any) => i.type === 'plane').length;
            const wheels = items.filter((i:any) => i.type === 'wheel').length;
            const plates = (h.plate?.Annotations || []).length;
            const state = {index, problematic, planes, wheels, plates};
            state.index = index + 1;
            state.problematic = problematic;
            state.planes = planes;

            return state;
        } catch (ex) {
            console.error('failed to get state:', ex);
        }
    }

    

}