// prettier-ignore
import { AppBar,  Drawer as DrawerMui } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { Redirect, Route, Router } from "react-router-dom";
import { history } from "./configureStore";
import {   AxlesPage, PlatesPage, VideoPage, InsidePage2, RawPage, LoginPage, PlanesPage } from "./pages";
import { withRoot } from "./withRoot";
import { Ribbon, Drawer } from './components';
import common from './services/commonService';
import scriptsService from './services/scriptingService';
import searchService from "./services/searchService";
import gatewayService from "./services/gatewayService";
import treeService from './services/treeService';
import loginService from "./services/loginService";
import plateAuditService from "./services/plateAuditService";
import orderService from "./services/orderService";
import lightsService from './services/lightsService';
import hazardService from "./services/hazardService";
import charsService from "./services/charsService";
import reflectiveService from "./services/reflectiveService";
import planesService from "./services/planesService";

/// @ts-ignore
const ProtectedRoute = ({ component: Component,  ...rest }) => {
  return (
    <Route {...rest} render={
      props => {
        if (common.loggedIn) {
          return <Component {...rest} {...props} />
        } else {
          return <Redirect to={
            {
              pathname: '/login',
              state: {
                from: props.location
              }
            }
          } />
        }
      }
    } />
  )
}


function Routes() {
	const classes = useStyles();

 
	return (
		<div className={classes.content}>
			<Route exact={true} path="/" component={PlatesPage} >
        {/* <Redirect to="/plates"></Redirect> */}
      </Route>
      <Route exact={true} path="/inside2" component={InsidePage2} />
      <Route exact={true} path="/axles" component={AxlesPage} />
      <Route exact={true} path="/plates" component={PlatesPage}/>
      <Route exact={true} path="/video" component={VideoPage}/>
      <Route exact={true} path="/Raw" component={RawPage}/>
      <Route exact={true} path="/login" component={LoginPage}/>
      <Route exact={true} path="/planes" component={PlanesPage}/>
		</div>
	);
}





function App() {
	const classes = useStyles();
  common.host = window.location.hostname;
  if(process.env.REACT_APP_SERVER_URL != null)
	common.rest = process.env.REACT_APP_SERVER_URL;
  common.initialize();
  scriptsService.initialize();
  searchService.initialize();
  gatewayService.initialize();
  treeService.initialize();
  loginService.initialize();
  plateAuditService.initialize();
  orderService.initialize();
  lightsService.initialize();
  hazardService.initialize();
  charsService.initialize();
  reflectiveService.initialize();
  planesService.initialize();

  const handleCallbacks = (key: string, data: any) => {

    switch(key) {
      case 'LoggedIn':
          break;
    }
  
  
  }

  const [height, setHeight] = React.useState('0px');

  common.addLog(`ImageLib REST server: ${process.env.REACT_APP_SERVER_URL}`);

  {
    const log = console.log.bind(console)
    console.log = (...args:any) => {
      common.addLog(args);
    }
  }

  {
    const error = console.error.bind(console)
    console.error = (...args:any) => {
      common.addError(args);
    }
  }

  console.log('Program launched ...');

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallbacks(msg.name as string, msg.data);
    });
    // should launch login
    common.notify('ApplicationRendered');

    return (() => {
      subscription.unsubscribe()});
  }, []);
 

	return (
		<Router history={history}  >
			<div className={classes.root}>
				<div className={classes.appFrame}  >
					<AppBar className={classes.appBar}>
            <Ribbon size={50} color={'dark'}></Ribbon>
						</AppBar>
            {/* <div style={{ background: '#29367d', color: 'white', width: '0px' }}> */}
            <Drawer></Drawer>
            {/* </div> */}
        	<Routes />
				</div>
			</div>
		</Router>
	);
}



const drawerWidth = 50;
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: "100%",
		zIndex: 1,
		overflow: "hidden",
	},
	appFrame: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100%",
	},
	appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
		position: "absolute",
	},
	navIconHide: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	drawerHeader: { ...theme.mixins.toolbar },
	drawerPaper: {
		width: 50,
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.up("md")]: {
			width: drawerWidth,
			position: "relative",
			height: "100%",
		},
	},
	content: {
		backgroundColor: theme.palette.background.default,
		width: "100%",
		height: "calc(100% - 50px)",
		marginTop: 50,
		[theme.breakpoints.up("sm")]: {
			height: "calc(100% - 50px)",
			marginTop: 50,
		},
	},
}));

export default withRoot(App);
