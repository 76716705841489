import React, {useState, useEffect, useRef} from "react";
import { Box,  IconButton, Tooltip,  FormControlLabel, Checkbox  } from "@material-ui/core";
import ResizeObserver from 'react-resize-observer';
import common from '../../services/commonService';
import { AspectRatio, Reorder as ReorderIcon, ViewModule as StitchedIcon, ArrowDownward as DownloadIcon} from "@material-ui/icons";
import pan from './pan';
import planesService from "../../services/planesService";

export function IdWrapper(props:any) {

  const[value, setValue] = useState(0);
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case 'PlanesRecordSelected':
        case 'PlanesChanged':
          setValue(v => v + 1);
          break;
      }
    });
    return (() => subscription.unsubscribe())
  }, []);

  const id = common.planes.selectedRow?.image;
  return ( 
  <Tooltip title="Image ID"> 
  <span> { id &&
    <span style={{padding:4, color:'white', background: 'rgba(0,0,0,0.2)'}}>{id}</span>
    }
  </span>
  </Tooltip> )


 
}

export function ControlsWrapper(props:any) {

  const[value, setValue] = useState(0);
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case 'PlanesRecordLoaded':
          setValue(v => v + 1);
          break;
      }
    });
    return (() => subscription.unsubscribe())
  }, []);

  const id = common.axles.imageId;
  const seqId = common.axles?.selectedRow?.id;

  return ( 

  <Box display="flex" flexDirection="row">
       <Tooltip title="Download raw image">
        <div>
        <IconButton   style={{color: 'white'}}
          onClick={() => common.notify('PlanesDownloadImage')}
          size="medium"
          color="primary" >
          <DownloadIcon />
        </IconButton>
        </div>
      </Tooltip>
     <Tooltip title="Unzoom">
        <IconButton  style={{color: 'white'}}
          onClick={() => common.notify('PlanesUnzoom')}
          size="medium"
          color="primary" >
          <AspectRatio />
        </IconButton>
      </Tooltip>
      <Tooltip title="Current record in JSON format">
        <div>
        <IconButton  disabled={common.app.context.json === ''}  style={{color: 'white'}}
          onClick={() => common.notify('ShowJsonRequest')}
          size="medium"
          color="primary" >
          <ReorderIcon />
        </IconButton>
        </div>
      </Tooltip>

  </Box>
  
  
  )
 
}




/**
 * Host the canvas with resizing
 * @param props 
 * @returns 
 */
export function CanvasWrapper(props: any) {


  const [canvasHeight, setCanvasHeight] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const canvasRef = useRef(null);

  const[value, setValue] = useState(0);

  const update = () => setValue(v => v + 1);


  /**
   * handle mouseMove
   * @param evt 
   */
  const handleMouseMove = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = planesService.canvas;
    planesService.canvas.mousePos = planesService.canvas.getPoint(evt);
    const editor = getEditor();
    if (evt.ctrlKey)
      pan.mouseMove(evt);
    else
      editor.mouseMove(evt);
      planesService.canvas.paint('wrap-move');
  }

  /**
   * Handle mouse down
   * @param evt 
   */
  const handleMouseDown = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = planesService.canvas;
 
    const editor = getEditor();
    if (evt.ctrlKey) 
      pan.mouseDown(evt);
    else if (editor)
      editor.mouseDown(evt);
    else
       pan.mouseDown(evt);
  }

  /**
   * handle mouse wheel - zoom
   * @param evt 
   */
  const handleMouseWheel = (evt: any) => {
    // evt.preventDefault();
    evt.stopPropagation();
    pan.mouseWheel(evt);
    planesService.canvas.paint('wrap-wheel');
  }

  /**
   * handle mouse up
   * @param evt 
   */
  const handleMouseUp = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = planesService.canvas;
    const editor = getEditor();
    if (evt.ctrlKey)
      pan.mouseUp(evt);
    else
      editor.mouseUp(evt);
   
  }

  /**
   * handle mouse leave the canvas
   * @param evt 
   */
  const handleMouseLeave = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    pan.mouseLeave(evt);
  }

  /**
   * handle mouse click
   * @param evt 
   */
  const handleMouseClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = planesService.canvas;
    const editor = getEditor();
    if (editor)
      editor.click(evt);
      planesService.canvas.paint('wrap-click');
  }

  /**
   * handle double click (start annotation)
   * @param evt 
   * @returns 
   */
  const handleDoubleClick = (evt: any) => {
    try {
      if (common.plates.settings.mode !== 'Annotation')
        return;

      evt.preventDefault();
      evt.stopPropagation();
      pan.doubleClick(evt);
      console.log('double clicked');
    } catch (ex) {
      console.error('failed on double click', ex);
    }
  }

  /**
   * callback handler
   * @param msg 
   * @param value 
   */
  const handleCallbacks = (msg: string, value: any) => {
    try {
      switch(msg) {
        case "PlanesRecordLoaded":
          update();
          break;
     
      }
    } catch (ex) {

    }
  }

  /**
   * single editor for now
   * @returns 
   */
  const getEditor = (): any => {
    return pan;
  }



  /**
   * on redraw
   */
  useEffect(() => {
    const canvas = (canvasRef.current as any) as HTMLCanvasElement;
    planesService.canvas.setCanvas(canvas);
    planesService.canvas.restoreMatrix();
  });

  /**
   * on creation
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallbacks(msg.name as string, msg.data);
    });
    return (() => subscription.unsubscribe())
  }, []);

  const w:number=props.width;
  const h:number=props.height;
  const zoomLeft:number = w - 20;

  planesService.canvas.windowSize = [w, h];

  return <Box display="flex" flexDirection="column" flex={1} width={w} height={h}>

    <div style={{width:w, height:h, overflow:'hidden', position:'relative'}}>
    <canvas ref={canvasRef} 
          onMouseDown={handleMouseDown}
          onWheel={handleMouseWheel}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          // onMouseLeave={handleMouseLeave}
          onClick={handleMouseClick}
          onDoubleClick={handleDoubleClick}
          width={1920}  
          height={1200} 
          style={{background:'lightGray'}}/>

              <Box display="flex" flexDirection="row" position="absolute" left={w - 160} top={h - 60}>
                <ControlsWrapper/>
              </Box>

            <Box position="absolute" left={w - 200} top={10}>
                <IdWrapper></IdWrapper>
              </Box>

    </div>
    
  </Box>


}