import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import common from '../../services/commonService';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import copy  from 'copy-to-clipboard';
import { AttachFile as AttachIcon } from '@material-ui/icons';

export default function HelpDialog(props:any) {
  // from props
  const { onClose, open, dlgMessage, dlgTitle } = props;




  const handleClose = (confirm: boolean) => {
    onClose(confirm);
  };



  const classes = useStyles();

  const content = `
  1. Click on + Plane to add a new plane annotation.
  2. Double click and drag to draw plane's outline rectangle
  3. Double click and drag inside plane's rectangle to draw the wheel
  4. Shift+Double click to draw the plate polygon
  5. Double click inside the polygon and drag downward for character height
  6. Specify text, extra and white chars attributes for plate
  7. Specify plane direction
  8. To delete wheel, plate or wheel click on corresponding delete icon
  9. Specify problematic for a problematic image

  

  
  `;
  return (
      <Dialog style={{overflow:'hidden'}}
      maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{}} id="alert-dialog-title">Planes annotation</DialogTitle>
        <DialogContent  style={{overflow: 'hidden'}}>
          <DialogContentText id="alert-dialog-description" style={{overflow: 'hidden'}}>
            <Box style={{ color: 'black'}} fontFamily="consolas" fontSize="12px">
              <textarea  spellCheck="false" style={{resize: 'none', width:'600px', height:'400px'}} defaultValue={content} ></textarea>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box display="flex" flex="1" flexDirection="row">
      
          <Box flexDirection="column" flex="1"/>
          <Button className={classes.button} onClick={() => handleClose(true)} color="primary" autoFocus>Ok</Button>
          </Box>
        </DialogActions>
      </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({ 
  button: {
    margin: 4,
    color: 'white',
    height: '25px',
    fontSize: '12px',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  combo: {
    width: 80,
  },
  grid: {

  },
}));