import React, {useState, useEffect} from 'react';
import {Box, FormControlLabel, Checkbox} from '@material-ui/core'
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import ResizeObserver from 'react-resize-observer';
import common from '../../services/commonService';
import { CanvasWrapper } from './canvasWrapper';
import { EditorsPanel } from './editorsPanel';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}



export function PlanesDivider(props: any) {

  const [value, setValue] = useState(0); // integer state

/**
 * general callback
 * @param arg 
 * @param value 
 */
const handleCallback = (key: string, value: any) => {
  try {
      switch(key) {
        case 'StitchedUrlChanged':
        case 'AxlesOverlapModeChanged':
        case "AxlesStitchMode":
          setValue(v => v + 1);
          break;
      }
    
  } catch (ex) {
    console.error('failed to handle callback: ', ex);
  }
}

const handleStitchChanged = (checked: boolean) => {
  try {
    // only if both stitched and single images exist
    if (!common.axles.overlapAllowChange)
      return;
    common.axles.overlapMode = !checked;
    setValue(v => v + 1);
    common.notify('AxlesOverlapModeChanged');
  } catch (ex) {
    console.error('failed to handle stitch changed:', ex);
  }
}

/**
 * subscribe to global notifications
 */
useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleCallback(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});

}, []);

const { height, width } = useWindowDimensions();
const stitchedUrl = common.axles.stitchedUrl || '';
const stitched = !common.axles.overlapMode;
// rendering
return ( 
  <Box display="flex" flex="1" flexDirection="column" style={{background:'whitesmoke'}}>


        <Box display="flex" flex="1" flexDirection="column" width={width-330 - 60} maxWidth={width- 330 - 60} height={height - 50}  >

          <Box display="flex" flex="1" height={height - 50 - 230} flexDirection="row">


            <Box display="flex" width ={width-560} height={height - 300}  >
                <CanvasWrapper width={width- 560} height={height-50-230-20}/>
             </Box>

        
            
            </Box>

            <Box display="flex"  height="5px" style={{background:'gray'}}></Box>

            <EditorsPanel/>

          


        

  
          

            </Box>


   

  
  </Box>
)

}