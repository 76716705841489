import React, {useState, useEffect} from 'react';
import { PlateList }  from './plateList';
import { PlatesFilter } from './platesFilter';
import Box from '@material-ui/core/Box'
import platesService from '../../services/platesService';
import commonService from '../../services/commonService';
import pan  from './pan';
import { useHotkeys } from 'react-hotkeys-hook';
import { DatasetsDialog } from './datasetsDialog';
import { PlatesSplitter } from './platesSplitters';

import 'react-reflex/styles.css';
import common from '../../services/commonService';
import { HazardData, InsideEntry, LightsBounding, ReflectiveData, VehicleBounding } from '../../data/platesData';
import {  Axle } from '../axles/axle'; 
import { Planes } from '../planes/planes';

/**
 * main plate annotation panel
 * @param props 
 * @returns 
 */
export function Plate(props: any) {
  const [value, setValue] = useState(0); // integer state
  const [datasetsOpen, setDatasetsOpen] = useState(false);

  // bind hotkeys
  useHotkeys('shift+n', () =>  hotKeyNext());
  useHotkeys('shift+l', () => hotkeyLighting());
  useHotkeys('shift+k', () => hotkeyColor());
  useHotkeys('shift+p', () => hotKeyPreviousImage());
  useHotkeys('shift+1', () => hotKeyTagType(0));
  useHotkeys('shift+2', () => hotKeyTagType(1));
  useHotkeys('esc', () => hotkeyEsc());
  

  const hotKeyTagType = (index:number) => {
    common.notify('PlatesTagType', index as any);
  }
  /**
   * Save and next
   */
  const hotKeyNext = () => {
    // ignore if annotations are not valid (button is disabled)
    if (common.plates.annotationsAreValid)
      common.notify('PlatesSaveRecord', null);
  }
  /**
   * dataset dialog close callback
   * @param value 
   */
  const handleDatasetsClose = (value:any) => {
    setDatasetsOpen(false);
  };

  /**
   * escape - cancels current canvas annotation
   */
  const hotkeyEsc = () => {
    common.notify('CancelCurrentAnnotation');
  }

  /**
   * round robin on lighting options
   * @returns 
   */
  const hotkeyLighting = () => {
    try {
      const rec = common.plates.selectedRecord;
      if (!rec) return;
      switch(rec.Lighting) {
        case 1: rec.Lighting = 2; break
        case 2: rec.Lighting = 3; break;
        case 3: rec.Lighting = 1; break;
      }
      setValue(v => v + 1);
      
    } catch (ex) {
      console.error('failed to handle lighting hotkey:', ex);
    }
  }

  /**
   * goto previous image
   */
  const hotKeyPreviousImage = () => {
    try {
      common.notify('GotoPreviousRecord');
    } catch (ex) {
      console.error('failed to handle  hotkeyPreviousImage:', ex);
    }
  }

  /**
   * toggle color property
   * @returns 
   */
  const hotkeyColor = () => {
    try {
      const rec = common.plates.selectedRecord;
      if (!rec) return;
      rec.ColoredImage = !rec.ColoredImage;
      setValue(v => v + 1);
    } catch (ex) {
      console.error('failed to handle lighting hotkey:', ex);
    }
  }

/**
 * clears current dataset
 */
const clearDataset = () => {
  try {
    common.notify('PlatesClearCanvas');
    common.plates.selectedRecord = null;
    common.plates.selectedAnnotation = null;
    common.plates.records = [];
    setValue(v => v + 1);
    common.notify('PlatesCleared');
  } catch (ex) {
    console.error('failed to clear dataset:', ex);
  }
}

/**
 * add vehicle buonding
 * @param bounding 
 */
const addVehicleBounding = (bounding: VehicleBounding) => {
  try {
    common.plates.vehicles.unshift(bounding);
    common.notify('VehicleBoundingAdded');
    common.unselectAnnotations();
    common.plates.selectedVehicle = bounding;
    common.notify('SelectedVehicleChanged');
  } catch (ex) {
    console.error('failed to add vehicle bounding:', ex);
  }
}

const addLightsBounding = (bounding: LightsBounding) => {
  try {
    common.plates.lights.unshift(bounding);
    common.notify('LightAdded');
    common.unselectAnnotations();
    common.plates.selectedLights = bounding;
    common.notify('SelectedLightsChanged');
  } catch (ex) {
    console.error('failed to add vehicle bounding:', ex);
  }
}

const addHazard = (bounding: HazardData) => {
  try {
    common.plates.hazards.unshift(bounding);
    common.notify('HazardAdded');
    common.unselectAnnotations();
    common.plates.selectedHazard = bounding;
    common.notify('SelectedHazardChanged');
    common.notify('AnnotationSelected');
  } catch (ex) {
    console.error('failed to add vehicle bounding:', ex);
  }
}

const addReflect = (bounding: ReflectiveData) => {
  try {
    common.plates.reflectives.unshift(bounding);
    common.notify('ReflectAdded');
    common.unselectAnnotations();
    common.plates.selectedReflective = bounding;
    common.notify('SelectedReflectiveChanged');
    common.notify('AnnotationSelected');
  } catch (ex) {
    console.error('failed to add vehicle bounding:', ex);
  }
}

const addInside = (inside: InsideEntry) => {
  try {
    common.plates.insides.unshift(inside);
    common.notify('InsideAdded');
    common.unselectAnnotations();
    common.plates.selectedInside = inside;
    common.notify('SelectedInsideChanged');
  } catch (ex) {
    console.error('failed to add vehicle bounding:', ex);
  }
}

/**
 * save plate with validation data
 * @param updatedRecord 
 */
// const savePlateValidation = (updatedRecord: any) => {
//   try {
//     const record = common.plates.selectedRecord;
//     const updated = {...record, ...updatedRecord};
//     delete updated.metadata;
//     delete updated.base64;
//     const path = common.selectedPath as string;
//     platesService.saveRecord(path, updated)
//     .then (reply => {
//       console.log('plate validation saved');
//       common.notify('GotoNextRecord');
//     })
//     .catch (reason => {
//       console.error('failed to save plateValidation:', reason);
//     });


//   } catch (ex) {
//     console.error('failed to save plateValidation:', ex);
//   }
// }

/**
 * update list entry based on the current record values
 */
const updateCurrentRecord = () => {
  try {
    const rec = common.plates.selectedRow;
    const selectedRecord = common.plates.selectedRecord;
    // guard - should not happen
    if (!rec || !selectedRecord)
      return;

    const bccm = common.plates.selectedRecord?.bccm;

    const pre = rec.TagCount;
    const ants = common.plates.selectedRecord?.Annotations;
    rec.TagCount = ants?.length;
    rec.PreTagged = platesService.isPreTagged(ants);
    rec.status = rec.TagCount === 0 ? "not-tagged" : "tagged";
    if (rec.PreTagged)
      rec.status = "pre-tagged";

    // add second token - bccm
    rec.status += bccm ? '+bccm' : '+';

    // third token - vehicle tagging exist 
    const vehiclized = common.plates.vehicles?.length > 0;
    rec.status += vehiclized ? '+vehicles' : '+';

    const insided = common.plates.insides?.length > 0;
    rec.status += insided ? '+insides' : '+';


    // update tagged counter (approx - periodicamente aggiorna)
    if (pre === 0 && rec.TagCount > 0)
      common.plates.taggedRecords++;
    if (pre > 0 && rec.TagCount === 0)
      common.plates.taggedRecords--;

    rec.state.problematic = common.plates.selectedRecord?.Problematic;
    rec.plateNumber = rec.TagCount === 0 ? '' : ants[0].PlateNumber?.value;
    common.notify('PlateStateUpdated');
  } catch(ex) {
    console.error('failed to update current record state:', ex);
  }
}

/**
 * Save plate with annotation data
 */
const savePlate = () => {
  try {

    // update the list entry to reflect current record
    // updateCurrentRecord();
    common.notify("PlatesUpdateCurrentRow");

    // prepare data to save
    const cloned = { ...common.plates.selectedRecord };
    if (!cloned) throw('no platesData');
    delete cloned.metadata;
    delete cloned.base64;
    for (let i = 0; i < cloned.Annotations.length; i++)
      delete cloned.Annotations[i].InternalSegments;
      
    acceptPreTags(cloned.Annotations);

    //  common.notify('UpdateRecord', cloned);
    const path = common.selectedPath as string;
    // actual saving
    platesService.saveRecord(path, cloned)
    .then(response => {
      console.log('plate record saved');
      common.notify('GotoNextRecord');
    })
    .catch(reason => {
      console.error('failed to save record:', reason);
    });

  } catch (ex) {
    console.error('failed to save plate: ', ex);
  }
}

/**
 * updated status flags for accepted annotations
 * @param annotations image annotations
 */
const acceptPreTags = (annotations: any[]) => {
  try {
    const fields = ["PlateNumber","WhiteChars","Bilingual","PlateType","Nation","VehicleType",
    "ExtraType","Category","CharHeight","Poly","Rect","InternalPoly", "PlateType"];
  
    annotations.forEach(ant => {
      if (ant.Accepted) {
        fields.forEach(f => {
          ant[f].status = 'tagged';
        });
      }
      
    });
  } catch (ex) {
    console.error('failed on acceptPreTags', ex);
  }
}

/**
 * add a new annotation
 * @param ant 
 */
const addNewAnnotation = (ant: any) => {
  try {
    ant.Nation.value = common.plates.settings.defaultNation;
    ant.PlateNumber.value = '';
    ant.VehicleType.value = 2;
    ant.PlateType.value = 0;
    const annotations = common.plates.selectedRecord.Annotations;
    annotations.push(ant);
    common.notify('AnnotationAdded');
  } catch (ex) {
    console.error('failed to add new annotation:', ex);
  }
}

/**
 * delete selected annotation
 * @returns 
 */
const deleteAnnotation = (ant: any) => {
  try {
    if (!ant) throw('no annotation to delete');
    const annotations = common.plates.selectedRecord?.Annotations;
    const index = annotations.indexOf(ant);
    annotations.splice(index, 1);
    common.notify('AnnotationDeleted');
  } catch (ex) {
    console.error('failed to delete selected annotation: ', ex);
  }
}

const clearAnnotations = () => {
  try {
    var ants = common.plates.selectedRecord.Annotations;
    ants.splice(0,ants.length);
    common.plates.selectedAnnotation = null;
    common.notify('AnnotationDeleted');
  } catch (ex) {
    console.error('failed to clear annotations:', ex);
  }
}

/**
 * handle plate face selection
 * @param name 
 * @returns 
 */
const handlePlateFaceSelection = (pf: any) => {
  try {

    if (common.plates.selectedHazard) {
      handleHazardPlateFaceSelection(pf);
      return;
    }



    const selected = common.plates.selectedAnnotation;
    if (!selected) 
      return;

    // WTT-266 - not sanitized, as to not nodify uneffected records
    if (!selected.Category) {
      selected.Category = {};
    }

    // WTT-356 if regioned - . separate, otherwise use only name !! 
    selected.Category.value = pf ? pf.region ?  `${pf.region}.${pf.name}` :  `${pf.name}` : null;
    selected.Category.status = 'tagged';
    common.notify('AnnotationChanged');
  } catch (ex) {
    console.error('failed to handle plateFaceSelection:', ex);
  }
}

/**
 *  handle hazard tagging plate face selection
 * @param pf 
 */
const handleHazardPlateFaceSelection = (pf:any) => {
  try {
    const hazard = common.plates.selectedHazard;
    if (!hazard) return;
    hazard.id = pf ? pf.id || 'no id' : '';
    hazard.class = pf ? pf.class || 'no class': '';
    common.notify('HazardAnnotationChanged');
  } catch (ex) {
    console.error('failed to handle hazard plate plateface selection');
  }
}

/**
 * handle annotation selection
 * @returns 
 */
const handleAnnotationSelection = () => {
  try {
    const ant = common.plates.selectedAnnotation;
    const plateFaces = common.plates.plateFaces;
    const cat = ant.Category?.value;
    plateFaces.forEach(pf => pf.selected = false);

    if (!ant || !cat) 
      return;

    // convention - region.name
    const regionName = cat.split('.');
    if (regionName.length !== 2)
      return;
      
    const selectedPlate = plateFaces.find(pf => 
      pf.nation === ant.Nation?.value &&
      pf.region === regionName[0] && 
      pf.name.includes(regionName[1]));

    if (selectedPlate)
      selectedPlate.selected = true;

  } catch (ex) {
    console.error('failed to handle annotation selection:', ex);
  }
}

/**
 * general callback
 * @param arg 
 * @param value 
 */
const handleCallback = (arg: string, value: any) => {
  try {

    switch (arg) {

    case "ApplicationModeChanged":
      setValue(value => value + 1);
      break;

      case 'OkNext':
        savePlate();
        break;

      case "PlatesCancel":
        // loadPlate(common.plates.selectedRecord.id);
        break;

      case 'SelectAnnotation':
        setValue(value => value + 1);
        break;

      case 'PlateFaceSelected':
        handlePlateFaceSelection(value);
        setValue(value => value + 1);
        break;

      // case "PlateValidatorOkNext":
      //   savePlateValidation(value as any);
      //   setValue(value => value + 1);
      //   break;


      case "PlatesSelectDataset":
        setDatasetsOpen(true);
        common.notify('DatasetsDialogOpened');
        
        break;
        
        // case "PlatesDatasetsArrived":
        //   setDatasetsOpen(true);
        // break;

        case "PlatesLoadDataset":
          break;

        case "AddVehicleBounding":
          addVehicleBounding(value as VehicleBounding);
          break;

        case 'AddLightsBounding':
          addLightsBounding(value as LightsBounding);
          break;

        case 'AddHazard':
          addHazard(value as HazardData);
        break;

        case 'AddReflect':
          addReflect(value as ReflectiveData);
          break;

        case "AddInside":
          addInside(value as InsideEntry);
          break;

        case 'PlatesSaveRecord':
          // todo - prep to transfer to service
          common.notify('PlatesUpdateCurrentRow');
          // updateCurrentRecord();
          break;

        case "PlatesClearDataset":
          clearDataset();
          break;

        case 'PlatesRecordLoaded':
          common.notify('PlatesUpdateCurrentRow');
          // updateCurrentRecord();
          break;

    }

  } catch (ex) {
    console.error('failed to handle callback: ', ex);
  }
}

/**
 * subscribe to global notifications
 */
useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleCallback(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});

}, []);

const handlers = {
  MOVE_UP: (event:any) => console.log("Move up hotkey called!")
};


const record = common.plates.selectedRecord;


return <Box display="flex" flex="1" flexDirection="row"  >
  { common.mode === 'PLATES' && 
    <Box display = "flex" flex = "1" flexDirection="row">
      <Box display="flex" flexDirection="column">
      <PlateList></PlateList>
      </Box>
      <PlatesSplitter></PlatesSplitter>
    </Box>
  }

  { common.mode === 'AXLES' && <Axle/>}

  {common.mode === 'PLANES' && <Planes/>}
  
  <DatasetsDialog open={datasetsOpen}  onClose={handleDatasetsClose}></DatasetsDialog>
</Box>
}